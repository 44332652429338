import React from 'react'
import {Switch,Route,useRouteMatch} from 'react-router-dom'
import Login from './Login'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'
export default function App(){
    let { path, url } = useRouteMatch();
    return(
        <div className="auth-wrapper align-items-stretch aut-bg-img">
            <div className="flex-grow-1">
                <div className="h-100 d-md-flex align-items-center auth-side-img">
                    <div className="col-sm-10 auth-content w-auto">
                    <img src={`${process.env.PUBLIC_URL}/assets/logo_putih2.png`} alt="" style={{width:200}} className="img-fluid" />
                    <h1 className="text-white my-4">Selamat Datang</h1>
                    <h4 className="text-white font-weight-normal">Silahkan masuk dengan akun anda dan nikmati pengalaman terbaik anda sebagai mentor</h4>
                    </div>
                </div>
                <div className="auth-side-form">
                    <div className=" auth-content">
                    <img src={`${process.env.PUBLIC_URL}/wakoollogo1.png`} style={{width:200,display:"block",margin:'0 auto',marginBottom:50}} alt="" className="img-fluid mb-4 d-block" />
                    <Switch>
                        <Route exact path={`${path}`}>
                            <Login/>
                        </Route>
                        <Route path={`${path}/signup`}>
                            <SignUp path={path}/>
                        </Route>
                        <Route path={`${path}/forgot-password`}>
                            <ForgotPassword path={path}/>
                        </Route>
                    </Switch>
                    </div>
                </div>
            </div>
        </div>
    )
    
}