import React from 'react'

const DataKosong = ({ text }) => {
  return (
    <div className="col-12 text-center">
      <img
        style={{ width: "300px" }}
        src={process.env.PUBLIC_URL + `/assets/images/no-data.jpg`}
        alt="no-data"
      />
      <p>{text}</p>
    </div>
  );
};

export default DataKosong;
