import React, { useState, useEffect, useContext } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useParams,
} from "react-router-dom";
import { ContextProvider } from "../../../context/BaseContext";
import swal from "sweetalert";
import { LoadingBall } from "../../../component/Loading";
import DataKosong from "../../../component/DataKosong";

export default function App({ path }) {
  const context = useContext(ContextProvider);
  const history = useHistory();
  const { idQuiz } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getData() {
    setLoading(true);
    let response = await context.getRequest(`getQuizById/${idQuiz}`);
    if (response) {
      // console.log(response.data.data.data[0]);
      var dt = response.data.data.data[0];
      setData(dt.questions);
      setLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Pertanyaan</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!" onClick={() => history.goBack()}>
                    Kuis
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!">Pertanyaan</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <button
            type="button"
            onClick={() => {
              history.goBack();
            }}
            className="btn btn-outline-secondary mr-2"
          >
            <i className="fas fa-arrow-left mr-1" />
            Kembali
          </button>
          <button
            onClick={() => {
              history.push(`${path}/add-soal/${idQuiz}`);
            }}
            className="btn btn-primary"
          >
            <i className="fa fa-plus mr-1" />
            Tambah Pertanyaan
          </button>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
              <div className="user-profile-list">
                <div className="dt-responsive table-responsive">
                  {loading ? (
                    <LoadingBall />
                  ) : (
                    <table id="user-list-table" className="table nowrap">
                      <tbody>
                        <RenderQuestions />
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  function RenderQuestions() {
    if (data.length != 0) {
      console.log(data);
      return data.map((val, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="align-middle mb-2">
                <p className="m-b-0 d-inline-block mr-1">{val.num}.</p>
                <div
                  className="d-inline-block"
                  dangerouslySetInnerHTML={{ __html: val.text }}
                ></div>
              </div>
              {val.answers.map((v, i) => {
                var i = `feather icon-x`;
                if (v.correct == 1) {
                  i = `feather icon-check`;
                }
                return (
                  <div className="align-middle ml-3 mb-1">
                    <p className="m-b-0 d-inline-block mr-2">
                      <i className={i}></i>
                    </p>
                    <div
                      className="d-inline-block"
                      dangerouslySetInnerHTML={{ __html: v.text }}
                    ></div>
                  </div>
                );
              })}
            </td>
            <td className="text-right">
              <button
                type="button"
                onClick={() => {
                  history.push({
                    pathname: `${path}/edit-soal/${val.id}/${idQuiz}`,
                    data: val,
                  });
                }}
                class="btn btn-outline-warning btn-sm"
              >
                <i class="feather icon-edit-2"></i>&nbsp;Edit
              </button>
              &nbsp;
              <button
                type="button"
                onClick={() => {
                  deleteSoal(val.id);
                }}
                class="btn btn-outline-danger btn-sm"
              >
                <i class="feather icon-trash-2"></i>&nbsp;Hapus
              </button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center">
            <DataKosong text="Kuis ini Belum memiliki pertanyaan" />
          </td>
        </tr>
      );
    }
  }
  async function deleteSoal(id) {
    swal({
      title: "Anda yakin?",
      text: "Jika anda menghapus pertanyaan ini, pertanyaan dan pilihan ganda akan terhapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      buttons: ["Tidak jadi", "Ya, saya akan hapus!"],
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await context.getRequest(`delete-question/${id}`);
        if (response) {
          swal("Hapus pertanyaan berhasil dilakukan", {
            icon: "success",
          });
          getData();
        }
      } else {
        swal("Tidak jadi hapus pertanyaan ini");
      }
    });
  }
}
