import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useForm } from "react-hook-form";
import MessageError from "../../../../component/MessageError";
import { ContextProvider } from "../../../../context/BaseContext";
import { BreadCrumb } from "../../../../component/breadcrumb";
import { removeSecTime } from "../../../../util/Functions";
import moment from "moment";

export default function AddLiveKelas() {
  const history = useHistory();
  const { id } = useParams();
  const context = useContext(ContextProvider);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const [keys, setKey] = useState("");
  const [listJadwal, setListJadwal] = useState([
    // {
    //   id: randomKey(),
    //   topic: "dummy 1",
    //   agenda: "agenda dummy 1",
    //   tanggal: "20/07/2021",
    //   jam_mulai: "10:00",
    //   jam_akhir: "12:00",
    // }
  ]);
  const [listJadwalUsed, setListJadwalUsed] = useState([]);
  const [jadwalKonflik, setJadwalKonflik] = useState([]);

  const [isValidStartTimeJadwal, setIsValidStartTimeJadwal] = useState("");
  const [isValidEndTimeJadwal, setIsValidEndTimeJadwal] = useState("");

  function randomKey() {
    var i = "";
    for (var a = 0; a < 5; a++) {
      i = i.concat(Math.ceil(Math.random() * 10));
    }
    return i;
  }

  async function checkDateLive() {
    let { zoom_use } = await JSON.parse(localStorage.profile_mentor);

    if (
      zoom_use == null &&
      watch("date_start") &&
      watch("date_end") &&
      watch("time_start") &&
      watch("time_end")
    ) {
      let form = {
        date_start: watch("date_start"),
        date_stop: watch("date_end"),
      };
      let response = await context.postRequest("getAvailableSchedule", form);
      if (response) {
        setListJadwalUsed(response.data);
      }
    }
  }

  const onSubmit = async (data) => {
    // console.log({ data, listJadwal });

    let status = data.is_terbit ? 2 : 0;
    let form = {
      title: data.title,
      class_id: id,
      date_start: data.date_start,
      date_end: data.date_end,
      time_start: data.time_start,
      time_end: data.time_end,
      status: status,
      price: data.price,
      min_user: data.min_user,
      detail: listJadwal,
    };
    // console.log(form);

    let tk = listJadwal.filter((val) => val.topic == "");
    let ak = listJadwal.filter((val) => val.agenda == "");
    let tgk = listJadwal.filter((val) => val.tanggal == "");
    let jmk = listJadwal.filter((val) => val.jam_mulai == "");
    let jak = listJadwal.filter((val) => val.jam_akhir == "");

    if (
      tk.length == 0 &&
      ak.length == 0 &&
      tgk.length == 0 &&
      jmk.length == 0 &&
      jak.length == 0
    ) {
      setJadwalKonflik([]);
      let response = await context.postRequest("createLiveClass", form);
      console.log(response);
      if (response.message == "Jadwal Input Konflik") {
        context.setNotification(
          "danger",
          "Data jadwal yang anda masukan konflik harap periksa kembali"
        );
      } else if (response.message == "Jadwal Konflik") {
        response.errors.forEach((item) => {
          if (item.not_valid > 0) {
            setJadwalKonflik(response.errors);
            document.getElementById(`topic_${item.id}`).focus();
          }
        });
      } else if (response.message == "Berhasil menyimpan data") {
        history.goBack();
        context.setNotification("success", "Live kelas berhasil ditambahkan");
        reset();
      } else {
        console.log("ELSEEE", response);
        context.setNotification(
          "danger",
          "Terjadi kesalahan, coba beberapa saat lagi"
        );
      }
    } else {
      context.setNotification(
        "danger",
        "Data tidak lengkap, Harap periksa kembali jadwal anda"
      );
    }
  };

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Tambah Live Kelas</h5>
              </div>
              <BreadCrumb
                pageName={"Tambah Live Kelas"}
                links={[
                  { url: "/kelas", name: "Kelas" },
                  { url: "", name: "Detail Kelas" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-8 mb-2">
                <div className="form-group row">
                  <label htmlFor="title" className="col-sm-2 col-form-label">
                    Judul
                  </label>
                  <div className="col-sm-10">
                    <input
                      {...register("title", {
                        required: "Judul live kelas harus diisi",
                      })}
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Masukkan judul live kelas"
                    />
                    {errors.title && (
                      <MessageError msg={errors.title.message} />
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="date_start"
                    className="col-sm-2 col-form-label"
                  >
                    Tanggal Mulai
                  </label>
                  <div className="col-sm-10">
                    <input
                      {...register("date_start", {
                        required: "Tanggal mulai harus diisi",
                      })}
                      onBlur={() => checkDateLive()}
                      type="date"
                      className="form-control"
                      id="date_start"
                      placeholder="Masukkan tanggal mulai kelas"
                    />
                    {errors.date_start && (
                      <MessageError msg={errors.date_start.message} />
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="date_end" className="col-sm-2 col-form-label">
                    Tanggal Akhir
                  </label>
                  <div className="col-sm-10">
                    <input
                      {...register("date_end", {
                        required: "Tanggal akhir harus diisi",
                      })}
                      min={watch("date_start") ? watch("date_start") : ""}
                      onBlur={() => checkDateLive()}
                      type="date"
                      className="form-control"
                      id="date_end"
                      placeholder="Masukkan tanggal akhir kelas"
                    />
                    {errors.date_end && (
                      <MessageError msg={errors.date_end.message} />
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor={`time_start`}
                    className="col-sm-2 col-form-label"
                  >
                    Waktu Mulai
                  </label>
                  <div className="col-sm-10">
                    <input
                      {...register("time_start", {
                        required: "Waktu mulai harus diisi",
                      })}
                      onBlur={() => checkDateLive()}
                      type="time"
                      className="form-control"
                      id={`time_start`}
                      placeholder="Masukkan waktu mulai"
                    />
                    {errors.time_start && (
                      <MessageError msg={errors.time_start.message} />
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor={`time_end`}
                    className="col-sm-2 col-form-label"
                  >
                    Waktu Akhir
                  </label>
                  <div className="col-sm-10">
                    <input
                      {...register("time_end", {
                        required: "Waktu akhir harus diisi",
                      })}
                      onBlur={() => checkDateLive()}
                      type="time"
                      className="form-control"
                      id={`time_end`}
                      placeholder="Masukkan waktu akhir"
                    />
                    {errors.time_end && (
                      <MessageError msg={errors.time_end.message} />
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="min_user" className="col-sm-2 col-form-label">
                    Minimal Pendaftar
                  </label>
                  <div className="col-sm-10">
                    <input
                      {...register("min_user", {
                        required: "Minimal pendaftar harus diisi",
                      })}
                      defaultValue="0"
                      type="number"
                      className="form-control"
                      id="min_user"
                      placeholder="Masukkan minimal pendaftar kelas"
                    />
                    {errors.min_user && (
                      <MessageError msg={errors.min_user.message} />
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="price" className="col-sm-2 col-form-label">
                    Harga Kelas
                  </label>
                  <div className="col-sm-10">
                    <input
                      {...register("price", {
                        min: {
                          value: 1,
                          message: "Harga harus lebih dari Rp.0",
                        },
                        required: "Harga kelas harus diisi",
                      })}
                      type="number"
                      className="form-control"
                      id="price"
                      placeholder="Masukkan harga kelas"
                    />
                    {errors.price && (
                      <MessageError msg={errors.price.message} />
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-2 col-form-label" />
                  <div className="col-sm-10">
                    <div className="custom-control custom-checkbox mt-2">
                      <input
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        className="custom-control-input"
                        id="terbit"
                        {...register("is_terbit")}
                      />
                      <label
                        style={{ cursor: "pointer" }}
                        className="custom-control-label"
                        htmlFor="terbit"
                      >
                        Ajukan terbit live kelas
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {listJadwalUsed.length > 0 && (
                <div className="col-sm-4 p-0">
                  <div className="text-center">
                    <h5>Jadwal Live Terisi</h5>
                  </div>
                  <ul>
                    {listJadwalUsed.map((item, index) => {
                      return (
                        <li
                          style={{ color: "red" }}
                          className="pb-1"
                          key={index}
                        >
                          <span className="mr-1" style={{ color: "black" }}>
                            {moment(item.date).format("DD MMMM YYYY")}
                          </span>
                          <span
                            style={{
                              color: "rgb(150, 150, 150)",
                              fontStyle: "italic",
                            }}
                          >{`(${removeSecTime(item.start_date)} 
                          - ${removeSecTime(item.stop_date)})`}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>

            <hr />
            <div className="text-right">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsValidStartTimeJadwal("disable");
                  setIsValidEndTimeJadwal("disable");
                  let jadwal = listJadwal;
                  jadwal.push({
                    id: randomKey(),
                    topic: "",
                    agenda: "",
                    tanggal: "",
                    jam_mulai: "",
                    jam_akhir: "",
                  });
                  // setListJadwal(jadwal);
                  console.log(jadwal);
                  // setKey(randomKey());
                }}
                disabled={isValidStartTimeJadwal || isValidEndTimeJadwal}
                type="button"
                className="btn btn-primary"
              >
                Tambah Jadwal
              </button>
            </div>
            <hr />

            {listJadwal.map((item, idx) => {
              return (
                <div id="card-jadwal" className="card" key={`${item.id}`}>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        {jadwalKonflik.length != 0 &&
                          jadwalKonflik.map((val, i) => {
                            return (
                              <>
                                {val.not_valid > 0 && val.id == item.id ? (
                                  <span
                                    style={{ fontSize: "14px" }}
                                    className="badge badge-pill badge-danger"
                                  >
                                    <i className="fa fa-times" /> Jadwal Live
                                    Konflik
                                  </span>
                                ) : null}
                              </>
                            );
                          })}
                      </div>
                      <button
                        onClick={() => {
                          // deleteSoal(val.id)
                          let jadwal = listJadwal;
                          let ind = jadwal.findIndex((vv) => vv.id == item.id);
                          jadwal.splice(ind, 1);
                          setListJadwal(jadwal);
                          // console.log(j)
                          setKey(randomKey());
                          setIsValidStartTimeJadwal(false);
                          setIsValidEndTimeJadwal(false);
                        }}
                        type="button"
                        className="btn btn-sm btn-dark"
                      >
                        <i className="feather icon-trash-2" /> Hapus Jadwal
                      </button>
                    </div>
                    <div className="form-group row ml-4">
                      <label
                        htmlFor={`topic_${item.id}`}
                        className="col-sm-2 col-form-label"
                      >
                        Topic
                      </label>
                      <div className="col-sm-6">
                        <input
                          onChange={(event) => {
                            let jadwal = listJadwal;
                            let ind = jadwal.findIndex(
                              (vv) => vv.id == item.id
                            );
                            jadwal[ind]["topic"] = event.target.value;
                            setListJadwal(jadwal);
                            // console.log(jadwal);
                          }}
                          defaultValue={item.topic}
                          type="text"
                          className="form-control"
                          id={`topic_${item.id}`}
                          placeholder="Masukkan topic"
                        />
                      </div>
                    </div>

                    <div className="form-group row ml-4">
                      <label
                        htmlFor={`agenda_${item.id}`}
                        className="col-sm-2 col-form-label"
                      >
                        Agenda
                      </label>
                      <div className="col-sm-6">
                        <textarea
                          onChange={(event) => {
                            let jadwal = listJadwal;
                            let ind = jadwal.findIndex(
                              (vv) => vv.id == item.id
                            );
                            jadwal[ind]["agenda"] = event.target.value;
                            setListJadwal(jadwal);
                            // console.log(jadwal);
                          }}
                          rows={3}
                          defaultValue={item.agenda}
                          className="form-control"
                          id={`agenda_${item.id}`}
                          placeholder="Masukkan agenda"
                        ></textarea>
                      </div>
                    </div>

                    <div className="form-group row ml-4">
                      <label
                        htmlFor={`date_${item.id}`}
                        className="col-sm-2 col-form-label"
                      >
                        Tanggal
                      </label>
                      <div className="col-sm-6">
                        <input
                          min={watch("date_start") ? watch("date_start") : ""}
                          max={watch("date_end") ? watch("date_end") : ""}
                          onChange={(event) => {
                            let jadwal = listJadwal;
                            let ind = jadwal.findIndex(
                              (vv) => vv.id == item.id
                            );
                            jadwal[ind]["tanggal"] = event.target.value;
                            setListJadwal(jadwal);
                            // console.log(jadwal);
                          }}
                          defaultValue={item.tanggal}
                          type="date"
                          className="form-control"
                          id={`date_${item.id}`}
                          placeholder="Masukkan tanggal"
                        />
                      </div>
                    </div>

                    <div className="form-group row ml-4">
                      <label
                        htmlFor={`time_start_${item.id}`}
                        className="col-sm-2 col-form-label"
                      >
                        Waktu Mulai
                      </label>
                      <div className="col-sm-6">
                        <input
                          onChange={(event) => {
                            if (
                              event.target.value >= watch("time_start") &&
                              event.target.value <= watch("time_end")
                            ) {
                              setIsValidStartTimeJadwal("");

                              let jadwal = listJadwal;
                              let ind = jadwal.findIndex(
                                (vv) => vv.id == item.id
                              );
                              jadwal[ind]["jam_mulai"] = event.target.value;
                              setListJadwal(jadwal);
                            } else {
                              if (watch("time_start") && watch("time_end")) {
                                setIsValidStartTimeJadwal(item.id);
                              }
                            }
                          }}
                          onBlur={(e) => {
                            if (
                              e.target.value >= watch("time_start") &&
                              e.target.value <= watch("time_end")
                            ) {
                            } else {
                              e.currentTarget.value = "";
                            }
                          }}
                          defaultValue={item.jam_mulai}
                          type="time"
                          className="form-control"
                          id={`time_start_${item.id}`}
                          placeholder="Masukkan waktu mulai"
                        />
                        {isValidStartTimeJadwal == item.id ? (
                          <div className="mt-1">
                            <MessageError
                              msg={
                                "Waktu tidak boleh kurang atau lebih dari range yang telah Anda tentukan."
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row ml-4">
                      <label
                        htmlFor={`time_end_${item.id}`}
                        className="col-sm-2 col-form-label"
                      >
                        Waktu Akhir
                      </label>
                      <div className="col-sm-6">
                        <input
                          onChange={(event) => {
                            if (
                              event.target.value >= watch("time_start") &&
                              event.target.value <= watch("time_end")
                            ) {
                              setIsValidEndTimeJadwal("");
                              let jadwal = listJadwal;
                              let ind = jadwal.findIndex(
                                (vv) => vv.id == item.id
                              );
                              jadwal[ind]["jam_akhir"] = event.target.value;
                              setListJadwal(jadwal);
                              // console.log(jadwal);
                              // console.log("masuk");
                            } else {
                              setIsValidEndTimeJadwal(item.id);
                            }
                          }}
                          onBlur={(e) => {
                            if (
                              e.target.value >= watch("time_start") &&
                              e.target.value <= watch("time_end")
                            ) {
                            } else {
                              e.currentTarget.value = "";
                            }
                          }}
                          defaultValue={item.jam_akhir}
                          type="time"
                          className="form-control"
                          id={`time_end_${item.id}`}
                          placeholder="Masukkan waktu akhir"
                        />
                        {isValidEndTimeJadwal == item.id ? (
                          <div className="mt-1">
                            <MessageError
                              msg={
                                "Waktu tidak boleh kurang atau lebih dari range yang telah Anda tentukan."
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="form-group row mt-5">
              <label className="col-sm-2 col-form-label" />
              <div className="col-sm-10">
                <button
                  type="button"
                  onClick={() => {
                    history.goBack();
                  }}
                  className="btn btn-outline-secondary"
                >
                  <i className="fas fa-arrow-left mr-1" />
                  Kembali
                </button>
                <button type="submit" className="btn btn-primary ml-2">
                  <i className="fas fa-save mr-1" />
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
