import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ContextProvider } from "../../context/BaseContext";
import MessageError from "../../component/MessageError";
import Cropper from "react-easy-crop";
import {
  getCroppedImg,
  convertToBase64,
  dataURLtoFile,
} from "../../util/Functions";

export default function App({ path }) {
  const inputFile = useRef(null);
  const context = useContext(ContextProvider);
  const history = useHistory();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [fileToCrop, setFileToCrop] = useState("");
  const [nameFileCrop, setNameFileToCrop] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    // setRefresh(true)
    console.log("DATA ", data);

    let form = new FormData();
    form.append("nama", data.nama);
    form.append("description", data.deskripsi);
    if (file != "") {
      form.append("avatar", file);
    }
    form.append("email", data.email);
    form.append("phone", data.phone);
    form.append("website", data.website);
    form.append("linkedin", data.linkedin);
    form.append("facebook", data.facebook);
    form.append("youtube", data.youtube);

    console.log(data);
    let response = await context.postRequest("instruktur", form);
    if (response) {
      // console.log(response)
      context.setNotification("success", response.message);
      history.replace(`${path}`);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    setRefresh(true);
    try {
      const croppedImage = await getCroppedImg(fileToCrop, croppedAreaPixels);
      // let file = dataURLtoFile(croppedImage, nameFileCrop);

      if (croppedImage) {
        let newFile = dataURLtoFile(croppedImage, nameFileCrop);
        // console.log("XXX++++",croppedImage);
        // console.log("=>>>>>", {
        //   name: nameFileCrop,
        //   data: file,
        // });
        // console.log("NEWDF", newFile);
        // console.log("xxxx", croppedImage);

        setFileToCrop("");
        setFile(newFile);
        setImage(croppedImage);
        setRefresh(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  async function handleChangeCover(file) {
    setRefresh(true);
    if (file.length != 0) {
      if (file[0].type == "image/jpeg" || file[0].type == "image/png") {
        convertToBase64(file[0])
          .then((result) => {
            setRefresh(false);
            setFileToCrop(result);
            setNameFileToCrop(file[0].name.replace(/\s/g, "_"));
            // setFileToCrop(result);
            // let avatar64 = result.split(",");
            // setPreviewCover(result);
            // setSaveCover({
            //   name: file[0].name.replace(/\s/g, "_"),
            //   data: avatar64[1],
            // });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setRefresh(false);
        context.setNotification(
          "danger",
          "Maaf, file yang anda upload bukan gambar"
        );
      }
    }
  }

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Tambah Instruktur</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                    href={`${path}`}
                  >
                    Instruktur
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a onClick={(e) => e.preventDefault()} href="#!">
                    Tambah Instruktur
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card user-profile-list">
        <div className="card-body">
          <div className="row mt-3">
            <div style={{ textAlign: "-webkit-center" }} className="col-md-4">
              <div
                className=""
                style={{
                  position: "relative",
                  height: "200px",
                  width: "200px",
                }}
              >
                {fileToCrop ? (
                  <Cropper
                    image={fileToCrop}
                    crop={crop}
                    // rotation={rotation}
                    zoom={zoom}
                    aspect={3 / 3}
                    onCropChange={setCrop}
                    cropShape="round"
                    // onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                ) : (
                  <img
                    src={
                      !image
                        ? `${process.env.PUBLIC_URL}/assets/images/noImage.png`
                        : image
                    }
                    alt="userimage"
                    className="img-radius mb-4"
                    data-toggle="tooltip"
                    title="Joseph William"
                    style={{
                      objectFit:"cover",
                      display: "block",
                      width: "195px",
                      height: "195px",
                    }}
                  />
                )}
                <input
                  type="file"
                  onChange={(e) => {
                    handleChangeCover(e.target.files);
                  }}
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  accept="image/png, image/gif, image/jpeg"
                />
              </div>
              {fileToCrop ? (
                <button
                  onClick={() => showCroppedImage()}
                  type="button"
                  className="btn btn-primary mt-2"
                  style={{ display: "block", margin: "0 auto" }}
                  disabled={refresh}
                >
                  {refresh && (
                    <div
                      style={{ width: "20px", height: "20px" }}
                      className="spinner-border text-light mr-1"
                    />
                  )}
                  Crop Photo
                </button>
              ) : (
                <button
                  onClick={() => {
                    inputFile.current.click();
                  }}
                  type="button"
                  className="btn btn-primary mt-2"
                  style={{ display: "block", margin: "0 auto" }}
                  disabled={refresh}
                >
                  Tambah foto
                </button>
              )}
            </div>
            <div className="col-md-8">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Nama Instruktur
                  </label>
                  <div className="col-sm-8">
                    <input
                      {...register("nama", { required: "Nama harus diisi" })}
                      type="text"
                      className="form-control"
                      id="inputEmail3"
                      placeholder="Nama Instruktur"
                    />
                    {errors.nama ? (
                      <MessageError msg={errors.nama.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Email
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      {...register("email", {
                        required: "Email harus diisi",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Alamat email salah",
                        },
                      })}
                      type="text"
                      className="form-control"
                      id="inputEmail3"
                      placeholder="Email"
                    />
                    {errors.email ? (
                      <MessageError msg={errors.email.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Deskripsi
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      {...register("deskripsi")}
                      type="text"
                      className="form-control"
                      id="inputEmail3"
                      placeholder="Deskripsi"
                    />
                    {errors.deskripsi ? (
                      <MessageError msg={errors.deskripsi.message} />
                    ) : null}
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    No Ponsel
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      {...register("phone")}
                      type="text"
                      className="form-control"
                      id="inputEmail3"
                      placeholder="No Ponsel"
                    />
                    {errors.phone ? (
                      <MessageError msg={errors.phone.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Website
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      {...register("website")}
                      type="text"
                      className="form-control"
                      id="inputEmail3"
                      placeholder="Website"
                    />
                    {errors.website ? (
                      <MessageError msg={errors.website.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    LinkedIn
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      {...register("linkedin")}
                      type="text"
                      className="form-control"
                      id="inputEmail3"
                      placeholder="Linkedin"
                    />
                    {errors.linkedin ? (
                      <MessageError msg={errors.linkedin.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Facebook
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      {...register("facebook")}
                      type="text"
                      className="form-control"
                      id="inputEmail3"
                      placeholder="Facebook"
                    />
                    {errors.facebook ? (
                      <MessageError msg={errors.facebook.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Youtube
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      {...register("youtube")}
                      type="text"
                      className="form-control"
                      id="inputEmail3"
                      placeholder="Youtube"
                    />
                    {errors.youtube ? (
                      <MessageError msg={errors.youtube.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row mt-5">
                  <label className="col-sm-3 col-form-label" />
                  <div className="col-sm-8">
                    <button
                      type="button"
                      onClick={() => {
                        history.goBack();
                      }}
                      className="btn btn-outline-secondary"
                    >
                      <i className="fas fa-arrow-left mr-1" />
                      Kembali
                    </button>
                    <button type="submit" className="btn btn-primary ml-2">
                      <i className="fas fa-save mr-1" />
                      Simpan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
