import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import { useHistory, useParams } from "react-router-dom";
// import swal from "sweetalert";
// import { useForm } from "react-hook-form";
import DataTable from "../../../../component/DataTable";
import { LoadingBall } from "../../../../component/Loading";
// import { currencyFormat } from "../../../../util/Functions";

const DummyPrivate = [
    {
      id: 1,
      kode: "PO01",
      kelas: "kelas Dummy 1",
      tanggal: "3 agustus 2021",
      kuantitas: "3",
      type: "Online",
      status: "pengajuan",
      harga: "-",
    },
    {
      id: 2,
      kode: "PO02",
      kelas: "kelas Dummy 2",
      tanggal: "4 agustus 2021",
      kuantitas: "5",
      type: "Offline",
      status: "diskusi",
      harga: "-",
    },
    {
      id: 3,
      kode: "PO03",
      kelas: "kelas Dummy 3",
      tanggal: "5 agustus 2021",
      kuantitas: "7",
      type: "Offline",
      status: "sepakat",
      harga: "Rp 140.000",
    },
    {
      id: 4,
      kode: "PO04",
      kelas: "kelas Dummy 4",
      tanggal: "6 agustus 2021",
      kuantitas: "1",
      type: "Offline",
      status: "Dibatalkan user",
      harga: "-",
    },
    {
      id: 5,
      kode: "PO05",
      kelas: "kelas Dummy 5",
      tanggal: "10 agustus 2021",
      kuantitas: "8",
      type: "Offline",
      status: "Dibatalkan mentor",
      harga: "-",
    },
    {
      id: 6,
      kode: "PO06",
      kelas: "kelas Dummy 6",
      tanggal: "1 agustus 2021",
      kuantitas: "15",
      type: "Offline",
      status: "telah dibayar",
      harga: "Rp 140.000",
    },
  ];

export default function ListPrivateKelas({path}) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();


  const RenderItem = ({ val }) => {
    let hideAction = false;
    let menuunggu = false;

    switch (val.status) {
      case "telah dibayar":
        hideAction = true;
        menuunggu = true;
        break;
      case "Dibatalkan user":
        hideAction = true;
        break;
      case "Dibatalkan mentor":
        hideAction = true;
        break;

      default:
        break;
    }
    return (
      <tr>
        <td>{val.id}</td>
        <td>{val.kode}</td>
        <td>{val.kelas}</td>
        <td>{val.tanggal}</td>
        <td>{val.kuantitas}</td>
        <td>{val.type}</td>
        <td>{val.status}</td>
        <td>{val.harga}</td>
        <td>
          {menuunggu ? (
            <span class="badge badge-success">Menunggu</span>
          ) : (
            <i className="fas fa-ellipsis-h" />
          )}

          {!hideAction && (
            <div className="overlay-edit">
              {val.status == "pengajuan" && (
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    // deleteInstruktur(val.id);
                  }}
                  type="button"
                  className="btn btn-icon btn-danger"
                >
                  <i className="fas fa-times"></i>
                </a>
              )}
              {val.status == "diskusi" && (
                <>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `${path}/discuss-private-kelas/${val.id}`,
                        state: val,
                      });
                    }}
                    className="btn btn-icon btn-warning"
                  >
                    <i className="fas fa-comments"></i>
                  </a>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      // deleteInstruktur(val.id);
                    }}
                    type="button"
                    className="btn btn-icon btn-danger"
                  >
                    <i className="fas fa-times"></i>
                  </a>
                </>
              )}
              {val.status == "sepakat" && (
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    // history.push(`${path}/edit/${val.id}`);
                  }}
                  className="btn btn-icon btn-success"
                >
                  <i className="fas fa-dollar-sign"></i>
                </a>
              )}
            </div>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <div>
          <tr>
            <td className="pr-2">
              <h6>Pengaturan :</h6>
            </td>
            <td>
              <div className="form-group mb-0">
                <div className="switch switch-primary d-inline m-r-10">
                  <input
                    onChange={(event) => {}}
                    type="checkbox"
                    id="switch-online-private"
                    //   checked={is_live_class}
                  />
                  <label htmlFor="switch-online-private" className="cr" />
                </div>
                {/* <label
                  style={is_live_class ? { color: "green" } : { color: "red" }}
                >
                  {is_live_class
                    ? "Kelas Live Aktif"
                    : "Kelas Live Tidak Aktif"}
                </label> */}
                <label>Online</label>
                <div className="form-group mb-0">
                  <div className="switch switch-primary d-inline m-r-10">
                    <input
                      onChange={(event) => {}}
                      type="checkbox"
                      id="switch-offline-private"
                      //   checked={is_live_class}
                    />
                    <label htmlFor="switch-offline-private" className="cr" />
                  </div>
                  {/* <label
                    style={
                      is_live_class ? { color: "green" } : { color: "red" }
                    }
                  >
                    {is_live_class
                      ? "Kelas Live Aktif"
                      : "Kelas Live Tidak Aktif"}
                  </label> */}
                  <label>Offline</label>
                </div>
              </div>
            </td>
          </tr>
        </div>
        <div className="form-group mb-0">
          <div className="switch switch-primary d-inline m-r-10">
            <input
              onChange={(event) => {}}
              type="checkbox"
              id="switch-private"
              //   checked={is_live_class}
            />
            <label htmlFor="switch-private" className="cr" />
          </div>
          {/* <label style={is_live_class ? { color: "green" } : { color: "red" }}>
            {is_live_class ? "Kelas Live Aktif" : "Kelas Live Tidak Aktif"}
          </label> */}
          <label>status</label>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12">
            <div className="user-profile-list">
              <div className="dt-responsive table-responsive">
                {loading ? (
                  <LoadingBall />
                ) : (
                    <DataTable
                    className={"table dataTable-style nowrap"}
                    data={DummyPrivate}
                    totalRows={DummyPrivate.length}
                    show={[10, 20, 30, 40, 50]}
                    onChangeSearch={(val) => {
                      console.log(val);
                    }}
                    onChangeLimit={(val) => {
                      console.log(val);
                    }}
                    onChangePage={(val) => {
                      console.log(val);
                    }}
                    column={[
                      "No",
                      "Kode",
                      "Kelas",
                      "Tanggal",
                      "Kuantitas",
                      "Tipe",
                      "Status",
                      "Harga",
                      "Action",
                    ]}
                    renderItem={(item, i) => (
                      <RenderItem val={item} key={i} />
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
