import React from "react";
import { Link, useHistory } from "react-router-dom";

export const BreadCrumb = ({
  pageName = "",
  links = [{ url: "/", name: "", state: null }],
}) => {
  //example data array links
  // links = [{ url: "/", name: "", state: null }]

  const history = useHistory();
  // console.log(links);
  return (
    <ul className="breadcrumb">
      <li className="breadcrumb-item">
        <Link to="/">
          <i className="feather icon-home" />
        </Link>
      </li>
      {links.length !== 0 && links[0].name !== ""
        ? links.map((link, idx) => {
            if (idx + 1 != links.length) {
              return (
                <li key={idx} className="breadcrumb-item">
                  {link.url ? (
                    <Link
                      to={
                        link.state
                          ? { pathname: link.url, state: link.state }
                          : link.url
                      }
                    >
                      {link.name}
                    </Link>
                  ) : (
                    <a onClick={(e) => e.preventDefault()} href="#!">
                      {link.name}
                    </a>
                  )}
                </li>
              );
            } else {
              return (
                <li key={idx} className="breadcrumb-item">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                    href="#!"
                  >
                    {link.name}
                  </a>
                </li>
              );
            }
          })
        : null}
      <li className="breadcrumb-item">
        <a onClick={(e) => e.preventDefault()} href="#!">
          {pageName}
        </a>
      </li>
    </ul>
  );
};
