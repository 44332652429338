import React from 'react'
import Template from '../../component/Template'
import {Route, Switch,useHistory,useRouteMatch} from 'react-router-dom'

import Index from './index'
import Add from './add'
import Edit from './edit'

export default function App(){
    const history = useHistory()
    let { path, url } = useRouteMatch();
    return(
        <Template>
            <Switch>
                <Route exact path={`${path}`}>
                    <Index path={path} />
                </Route>
                <Route exact path={`${path}/add`}>
                    <Add path={path} />
                </Route>
                <Route exact path={`${path}/edit/:id`}>
                    <Edit path={path} />
                </Route>
            </Switch>
        </Template>
    )
}