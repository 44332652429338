import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import RenderDetailKelas from "./renderDetailKelas";
import AddSection from "./addSection";
import EditSection from "./editSection";
import AddContentVideo from "./addContentVideo";
import EditContentVideo from "./editContentVideo";
import AddContentPDF from "./addContentPDF";
import EditContentPDF from "./editContentPDF";
import AddContentKuis from "./addContentKuis";
import EditContentKuis from "./editContentKuis";
import FilePendukungContent from "./FilePendukung/router";
import AddLiveKelas from "./LiveKelas/addLiveKelas";
import EditLiveKelas from "./LiveKelas/editKelas";
import DiscussPrivate from "./PrivateKelas/discussPrivate";
import LiveZoom from "./LiveKelas/liveZoom";
import AddFilePendukung from './FilePendukung/GlobalFile/add'

export default function DetailKelas() {
  let { path, url } = useRouteMatch();

  useEffect(() => {}, []);
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <RenderDetailKelas path={url} />
      </Route>
      <Route path={`${path}/add-section`}>
        <AddSection />
      </Route>
      <Route path={`${path}/edit/:sectionId`}>
        <EditSection />
      </Route>
      <Route path={`${path}/add-content-video`}>
        <AddContentVideo />
      </Route>
      <Route path={`${path}/edit-content-video/:contentId`}>
        <EditContentVideo />
      </Route>
      <Route path={`${path}/add-content-PDF`}>
        <AddContentPDF />
      </Route>
      <Route path={`${path}/edit-content-PDF/:contentId`}>
        <EditContentPDF />
      </Route>
      <Route path={`${path}/add-content-kuis`}>
        <AddContentKuis />
      </Route>
      <Route path={`${path}/edit-content-kuis/:contentId`}>
        <EditContentKuis />
      </Route>
      <Route path={`${path}/file-pendukung/:contentId`}>
        <FilePendukungContent />
      </Route>
      <Route path={`${path}/new-live-class`}>
        <AddLiveKelas />
      </Route>
      <Route path={`${path}/live-zoom-class/:liveId`}>
        <LiveZoom />
      </Route>
      <Route path={`${path}/edit-live-class/:liveId`}>
        <EditLiveKelas />
      </Route>
      <Route path={`${path}/discuss-private-kelas/:privateId`}>
        <DiscussPrivate />
      </Route>
      <Route path={`${path}/add-file`}>
        <AddFilePendukung />
      </Route>
    </Switch>
  );
}
