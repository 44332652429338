import { useEffect, useState } from "react";
import HtmlContent from "../../../component/html-content";

//COMPONENTS SECTION
export const RenderSections = ({
  history,
  section,
  path,
  swal,
  context,
  getDataKelas,
  allSections,
  handleShowContent,
  listKuis,
}) => {
  async function deleteSection(id) {
    swal({
      title: "Anda yakin?",
      text: "Jika anda menghapus section ini, section dan semua materi akan terhapus",
      icon: "warning",
      dangerMode: true,
      buttons: ["Tidak jadi", "Ya, saya akan hapus!"],
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await context.postRequest(`deleteSection/${id}`);
        if (response) {
          swal("Hapus section berhasil dilakukan", {
            icon: "success",
          });
          getDataKelas();
        }
      }
    });
  }

  return (
    <>
      <tr>
        <td style={{ backgroundColor: "whitesmoke" }}>
          <div className="d-inline-block align-middle">
            <div className="d-inline-block">
              <h5 className="m-b-0">
                {section.urutan}. {section.title}
              </h5>
            </div>
          </div>
        </td>
        <td style={{ backgroundColor: "whitesmoke" }} className="text-center">
          <span
            style={{ fontSize: "14px", width: "100px" }}
            className="badge badge-light"
          >
            {section.contents.length} Materi
          </span>
          <div className="overlay-edit">
            <button
              onClick={() =>
                history.push({
                  pathname: `${path}/edit/${section.id}`,
                  state: section,
                })
              }
              type="button"
              className="btn btn-icon btn-warning"
            >
              <i className="feather icon-edit-2" />
            </button>
            <button
              onClick={() => deleteSection(section.id)}
              type="button"
              className="btn btn-icon btn-danger"
            >
              <i className="feather icon-trash-2" />
            </button>
          </div>
        </td>
      </tr>
      {section.contents.length != 0 ? (
        <>
          {section.contents.map((content, index) => (
            <RenderContents
              key={index}
              content={content}
              history={history}
              path={path}
              allSections={allSections}
              swal={swal}
              getDataKelas={getDataKelas}
              context={context}
              handleShowContent={handleShowContent}
              listKuis={listKuis}
            />
          ))}
        </>
      ) : (
        <tr>
          <td colSpan="2" className="text-center">
            <p>Section ini belum memiliki materi</p>
          </td>
        </tr>
      )}
      <tr id="empty">
        <td colSpan="2"></td>
      </tr>
    </>
  );
};

//====================== RENDER CONTENTS ========================

const RenderContents = ({
  content,
  history,
  path,
  allSections,
  swal,
  getDataKelas,
  context,
  handleShowContent,
  listKuis,
}) => {
  // console.log(content);
  const [totalFile, setTotalFile] = useState(0);

  async function getFilePendukung() {
    let response = await context.getRequest(
      `content-file?content_id=${content.id}`
    );
    if (response) {
      setTotalFile(response["data"]["data"].length);
    }
  }

  async function deleteContent(id) {
    swal({
      title: "Anda yakin?",
      text: "Jika anda menghapus materi ini, file video akan terhapus",
      icon: "warning",
      dangerMode: true,
      buttons: ["Tidak jadi", "Ya, saya akan hapus!"],
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await context.postRequest(`deleteContent/${id}`);
        if (response) {
          swal("Hapus materi berhasil dilakukan", {
            icon: "success",
          });
          getDataKelas();
        }
      }
    });
  }

  async function hanldeGetContentQuiz(id, contentName, contentType) {
    if (id) {
      let response = await context.getRequest(`getQuizById/${id}`);
      // console.log(response.data.data.data[0]);
      handleShowContent(contentName, response.data.data.data[0], contentType);
    } else {
      handleShowContent(contentName, null, contentType);
    }
  }

  useEffect(() => {
    let isResponse = true;
    if (isResponse) {
      getFilePendukung(content.id);
    }
    return () => {
      isResponse = false;
    };
  }, []);
  return (
    <tr style={{ fontStyle: "italic" }}>
      <td>
        <div className="d-inline-block align-middle ml-3">
          <div className="d-inline-block">
            <h5 className="mb-1">
              {content.urutan}. {content.title}
            </h5>
            <p className="m-b-0">
              <span className="mr-3">
                {content.content_type_id == 3 && (
                  <>
                    <i className="fas fa-file-pdf mr-1"></i> PDF
                  </>
                )}
                {content.content_type_id == 2 && (
                  <>
                    <i className="fas fa-question mr-1"></i> Kuis
                  </>
                )}
                {content.content_type_id == 1 && (
                  <>
                    <i className="fas fa-video mr-1"></i> Video
                  </>
                )}
              </span>
              <span className="mr-3">
                <i className="fas fa-clock mr-1"></i>
                {content.durasi} Menit
              </span>
              <span className="mr-3">
                {content.is_preview ? (
                  <>
                    <i className="fa fa-eye mr-1"></i>Dapat diakses tanpa
                    membeli kelas
                  </>
                ) : (
                  <>
                    <i className="fas fa-eye-slash mr-1"></i>Dapat diakses
                    setelah membeli kelas
                  </>
                )}
              </span>
              <span className="mr-3">
                <i className="fas fa-folder-open mr-1" />
                {totalFile} File pendukung
              </span>
            </p>
          </div>
        </div>
      </td>
      <td className="text-center">
        <i className="fas fa-ellipsis-h" />
        <div className="overlay-edit">
          <button
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: `${path}/file-pendukung/${content.id}`,
                state: { content },
              });
            }}
            type="button"
            className="btn btn-icon btn-success"
          >
            <i className="fas fa-folder-open" />
          </button>
          <button
            onClick={() => {
              if (content.content_type_id == 2) {
                // console.log(content.quiz?.id);
                hanldeGetContentQuiz(
                  content.quiz?.id,
                  content.title,
                  content.content_type_id
                );
              } else {
                handleShowContent(
                  content.title,
                  content.contents_url,
                  content.content_type_id
                );
              }
            }}
            type="button"
            className="btn btn-icon btn-info"
            data-toggle="modal"
            data-target="#preview-content"
          >
            <i className="feather icon-eye" />
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              if (content.content_type_id == 1) {
                history.push({
                  pathname: `${path}/edit-content-video/${content.id}`,
                  state: { allSections, content },
                });
              } else if (content.content_type_id == 3) {
                history.push({
                  pathname: `${path}/edit-content-PDF/${content.id}`,
                  state: { allSections, content },
                });
              } else {
                history.push({
                  pathname: `${path}/edit-content-kuis/${content.id}`,
                  state: { allSections, content, listKuis },
                });
              }
            }}
            type="button"
            className="btn btn-icon btn-warning"
          >
            <i className="feather icon-edit-2" />
          </button>
          <button
            onClick={() => deleteContent(content.id)}
            type="button"
            className="btn btn-icon btn-danger"
          >
            <i className="feather icon-trash-2" />
          </button>
        </div>
      </td>
    </tr>
  );
};

//=========================MODAL PREVIEW===================

export const ModalPreviewContents = ({
  title,
  url,
  type,
  handleCloseModal,
}) => {
  // console.log(title, url, type);
  if (type == 1) {
    return (
      <div
        className="modal fade"
        id="preview-content"
        tabIndex={-1}
        data-backdrop="static"
        data-keyboard="false"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div style={{ marginTop: "100px" }} className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {title}
              </h5>
              <button
                onClick={() => handleCloseModal()}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <video
                style={{ width: "100%" }}
                src={url}
                type="video/mp4"
                controls
                autoPlay={true}
                controlsList="nodownload"
                onContextMenu={(e) => e.preventDefault()}
              ></video>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type == 3) {
    return (
      <div
        className="modal fade"
        id="preview-content"
        tabIndex={-1}
        data-backdrop="static"
        data-keyboard="false"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <iframe
                title="content-pdf"
                src={`${url}#toolbar=0`}
                style={{
                  border: "none",
                  height: "80vh",
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type == 2) {
    // console.log("URL",url);
    return (
      <div
        className="modal fade"
        id="preview-content"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {/* <div className="text-center mb-2">
                <h6>{url?.title}</h6>
              </div> */}
              {url ? (
                url.questions.map((question, idx) => {
                  return (
                    <div key={idx} className="mb-3">
                      <div className="col-12">
                        <div className="row mb-1">
                          <span className="mr-1">{question.num}.</span>
                          <HtmlContent html={question.text} />
                        </div>
                      </div>
                      {question.answers.map((answer, index) => {
                        return (
                          <div key={index} className="row">
                            <div className="col-12 pl-4">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`question-answer${idx}`}
                                  id={answer.text}
                                  defaultValue={answer.text}
                                />
                                <label
                                  className="form-check-label kuis-answer"
                                  htmlFor={answer.text}
                                >
                                  <HtmlContent html={answer.text} />
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <div className="col-12 text-center">
                  <i
                    style={{ fontSize: "50px", color: "red" }}
                    className="fas fa-exclamation-triangle mb-2"
                  />
                  <p style={{ fontSize: "17px" }}>
                    Materi ini belum terkait dengan kuis manapun, mohon pilih
                    kuis terlebih dahulu
                  </p>
                </div>
              )}
            </div>
            {url && (
              <div className="modal-footer p-2">
                <button type="button" className="btn btn-primary">
                  <a style={{ color: "white" }} href="/kuis">
                    Ubah detail quiz
                  </a>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="modal fade"
        id="preview-content"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div style={{ marginTop: "150px" }} className="modal-dialog">
          <div className="modal-content p-5 d-flex align-items-center">
            <div className="spinner-border text-primary" role="status" />
          </div>
        </div>
      </div>
    );
  }
};

//END COMPONENT SECTION
