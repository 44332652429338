import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import { ContextProvider } from "../../context/BaseContext";
import {
  // RenderButtonStatus,
  RenderStatus,
  RenderStatusClasses,
} from "./component-kelas";
import { BreadCrumb } from "../../component/breadcrumb.js";
import DataKosong from "../../component/DataKosong";
import { LoadingBall } from "../../component/Loading";

const RenderIndex = ({ path }) => {
  const history = useHistory();
  const context = useContext(ContextProvider);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const [classes, setClasses] = useState([]);
  const [draft, setDraft] = useState(0);
  const [diajukan, setDiajukan] = useState(0);
  const [diterbitkan, setDiterbitkan] = useState(0);
  const [ditolak, setDitolak] = useState(0);

  async function getDataKelasMentor(page) {
    setLoading(true);
    let response = await context.getRequest(`getKelasByMentor?page=${page}`);
    if (response) {
      setClasses([...classes, ...response.data.data]);

      if (response.data.data) {
        setDraft(cekStatus([...classes, ...response.data.data], 0));
        setDiajukan(cekStatus([...classes, ...response.data.data], 1));
        setDiterbitkan(cekStatus([...classes, ...response.data.data], 2));
        setDitolak(cekStatus([...classes, ...response.data.data], 3));
      }
      setLoading(false);
    }
  }

  async function deleteKelas(id) {
    swal({
      title: "Anda yakin?",
      text: "Jika anda menghapus kelas ini, kelas dan semua materi akan terhapus",
      icon: "warning",
      dangerMode: true,
      buttons: ["Tidak jadi", "Ya, saya akan hapus!"],
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await context.postRequest(`MentorDeleteKelas/${id}`);
        if (response) {
          swal("Hapus kelas berhasil dilakukan", {
            icon: "success",
          });
          window.location.reload();
        }
      }
    });
  }

  async function hendleAjukanKelas(name, id) {
    swal({
      title: `Ajukan penerbitan kelas ini?`,
      text: `jika ya, admin akan mengecek kelas "${name}" apakah memenuhi standar penerbitan atau tidak`,
      icon: "warning",
      dangerMode: true,
      buttons: ["Tidak jadi", "Ya, ajukan!"],
    }).then(async (result) => {
      // console.log(result);
      if (result) {
        let response = await context.postRequest(`MentorUpdateKelas/${id}`, {
          public_status: 1,
        });
        if (response) {
          swal(
            "Admin akan menerbitkan kelas jika memenuhi standar penerbitan",
            {
              icon: "success",
              title: "Kelas berhasil diajukan",
            }
          );
          window.location.reload();
        }
      } else {
        console.log("tidak jadi");
      }
    });
  }

  function cekStatus(data, status) {
    let d = data.filter((val) => val.public_status == status);
    return d.length;
  }

  const RenderKelasItem = ({ item }) => {
    const [errorCover, setErrorCover] = useState(false);
    return (
      <div className="col-xl-3 col-md-4">
        <div className="card user-card user-card-1">
          <div className="card-header border-0 p-2 pb-0">
            <div
              style={{ backgroundColor: "whitesmoke" }}
              className="cover-img-block text-center"
            >
              <img
                src={
                  errorCover
                    ? process.env.PUBLIC_URL + `/assets/images/noImage.png`
                    : item.img
                }
                alt="cover-class"
                className="img-fluid"
                style={{ height: "145px" }}
                onError={() => setErrorCover(true)}
              />
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="user-about-block text-center mt-1">
              <div className="row align-items-end">
                <div className="col text-left pb-2">
                  <i className="icon feather icon-star-on text-c-yellow f-20">
                    {" "}
                    {parseFloat(item.total_vote ? item.total_vote : 0)}
                  </i>
                </div>
                <div
                  style={{ paddingBottom: "10px" }}
                  className="col d-flex justify-content-center pr-2 pl-2"
                >
                  <RenderStatus status={item.public_status} />
                  {/* <RenderButtonStatus
                            hendleAjukanKelas={hendleAjukanKelas}
                            status={item.public_status}
                            namaKelas={item.title}
                            idKelas={item.id}
                          /> */}
                </div>
                <div className="col text-right pb-2">
                  {item.total_siswa == 0 && (
                    <i
                      onClick={() => deleteKelas(item.id)}
                      className="icon feather icon-trash-2 text-c-red f-20 delete"
                    ></i>
                  )}
                </div>
              </div>
            </div>
            <div className="text-center">
              <h5 className="mb-3 mt-3">
                {item.title.length > 21
                  ? item.title.substring(0, 20) + "..."
                  : item.title}
              </h5>
              <div className="row mt-1 mb-1">
                <div className="col-3 p-0">
                  <h6 className="mb-1">{item.total_durasi}</h6>
                  <p className="mb-0">Menit</p>
                </div>
                <div className="col-3 p-0">
                  <h6 className="mb-1">{item.total_content}</h6>
                  <p className="mb-0">Materi</p>
                </div>
                <div className="col-3 p-0">
                  <h6 className="mb-1">{item.total_siswa}</h6>
                  <p className="mb-0">Siswa</p>
                </div>
                <div className="col-3 p-0">
                  <h6 className="mb-1">{item.total_favorit}</h6>
                  <p className="mb-0">Favorit</p>
                </div>
              </div>
            </div>
            <hr className="wid-80 b-wid-3 my-3" />
            <div className="d-flex justify-content-center">
              <button
                style={{ fontWeight: "bold", fontSize: "12px" }}
                onClick={() => history.push(`${path}/detail/${item.id}`)}
                className="btn btn-outline-primary btn-sm"
              >
                DETAIL
              </button>
              <button
                style={{ fontWeight: "bold", fontSize: "12px" }}
                onClick={() =>
                  history.push({
                    pathname: `${path}/edit/${item.id}`,
                    // state: item,
                  })
                }
                className="btn btn-outline-success btn-sm ml-1"
              >
                EDIT
              </button>
              {item.public_status == 0 && (
                <button
                  style={{ fontWeight: "bold", fontSize: "12px" }}
                  onClick={() => hendleAjukanKelas(item.title, item.id)}
                  className="btn btn-outline-info btn-sm ml-1"
                >
                  AJUKAN
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  function atEnd() {
    var c = [
      document.scrollingElement.scrollHeight,
      document.body.scrollHeight,
      document.body.offsetHeight,
    ].sort(function (a, b) {
      return b - a;
    }); // select longest candidate for scrollable length
    return window.innerHeight + window.scrollY + 2 >= c[0]; // compare with scroll position + some give
  }

  async function checkScrollPosition() {
    if (atEnd()) {
      setPage(page + 1);
    }
  }

  useEffect(() => {
    // console.log('next offset', offset)
    let isSubscribed = true;
    if (isSubscribed) {
      getDataKelasMentor(page);
      window.addEventListener("scroll", checkScrollPosition);
    }
    return () => {
      isSubscribed = false;
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, [page]);

  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Kelas</h5>
              </div>
              <BreadCrumb pageName={"Kelas"} />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <button
            onClick={() => {
              history.push(`${path}/add`);
            }}
            className="btn btn-primary"
          >
            <i className="fa fa-plus mr-1" />
            Tambah Kelas
          </button>
        </div>
        <div className="card-body">
          <RenderStatusClasses
            draft={draft}
            diajukan={diajukan}
            diterbitkan={diterbitkan}
            ditolak={ditolak}
          />
          <div className="row">
            {loading && page == 1 ? (
              <LoadingBall />
            ) : (
              <>
                {classes.length != 0 ? (
                  <>
                    {classes?.map((item, idx) => (
                      <RenderKelasItem item={item} key={idx} />
                    ))}
                  </>
                ) : (
                  <DataKosong text={"Opss, kamu belum memiliki kelas"} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RenderIndex;
