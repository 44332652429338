import React, { useContext, useState, useEffect } from "react";
import { ContextProvider } from "../context/BaseContext";
import { getStorage } from "../util/Functions";
import { useHistory, Link } from "react-router-dom";
export default function App(props) {
  const history = useHistory();
  const context = useContext(ContextProvider);
  const [profile, setProfile] = useState(null);
  async function getProfile() {
    let pro = await getStorage("profile_mentor");
    if (pro) {
      let p = JSON.parse(pro);
      setProfile(p);
      // console.log(p)
    } else {
      history.replace("auth");
    }
  }
  // useEffect(async ()=>{
  //   await context.getCountNotif()
  // },[context.discussCountNotif])
  useEffect(() => {
    getProfile();
  }, [context.profile]);
  return (
    <>
      <nav className="pcoded-navbar theme-horizontal menu-light brand-blue">
        <div className="navbar-wrapper container">
          <div
            className="navbar-content sidenav-horizontal"
            id="layout-sidenav"
          >
            <ul className="nav pcoded-inner-navbar sidenav-inner">
              <li className="nav-item pcoded-menu-caption">
                <label>Navigation</label>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-home" />
                  </span>
                  <span className="pcoded-mtext">Dashboard</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/kelas" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-book" />
                  </span>
                  <span className="pcoded-mtext">Kelas</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/kuis" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-check-square" />
                  </span>
                  <span className="pcoded-mtext">Kuis</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/chat" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-navigation" />
                  </span>
                  <span className="pcoded-mtext">Chat</span>
                  {context.discussCountNotif > 0 ? (
                    <span style={{ color: "#D71C20" }}>
                      {context.discussCountNotif > 99
                        ? "99+"
                        : context.discussCountNotif}
                    </span>
                  ) : null}
                </a>
              </li>
              <li className="nav-item">
                <a href="/notifikasi" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-bell" />
                  </span>
                  <span className="pcoded-mtext">Notifikasi</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/withdraw" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-credit-card" />
                  </span>
                  <span className="pcoded-mtext">Witdraw</span>
                </a>
              </li>
              {profile != null && profile.instansi == 1 ? (
                <li className="nav-item">
                  <a href="/instruktur" className="nav-link ">
                    <span className="pcoded-micon">
                      <i className="feather icon-users" />
                    </span>
                    <span className="pcoded-mtext">Instruktur</span>
                  </a>
                </li>
              ) : null}
              <li className="nav-item">
                <a href="/live-schedule" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-video" />
                  </span>
                  <span className="pcoded-mtext">Jadwal Live Kelas</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <header className="navbar pcoded-header navbar-expand-lg navbar-light header-blue">
        <div className="container">
          <div className="m-header">
            <a className="mobile-menu" id="mobile-collapse" href="#!">
              <span />
            </a>
            <a href="#!" className="b-brand">
              {/* ========   change your logo hear   ============ */}
              <img
                src={`${process.env.PUBLIC_URL}/assets/logo_putih2.png`}
                style={{ width: 150, height: 50 }}
                alt=""
                className="logo"
              />
            </a>
            <a
              href="#!"
              className="mob-toggler"
              onClick={(e) => e.preventDefault()}
            >
              <i className="feather icon-more-vertical" />
            </a>
          </div>
          <div className="collapse navbar-collapse">
            {/* <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a href="#!" className="pop-search"><i className="feather icon-search" /></a>
                <div className="search-bar">
                  <input type="text" className="form-control border-0 shadow-none" placeholder="Search hear" />
                  <button type="button" className="close" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              </li>
            </ul> */}
            <ul className="navbar-nav ml-auto">
              {/* <li>
                <div className="dropdown">
                  <a className="dropdown-toggle" href="#" data-toggle="dropdown"><i className="icon feather icon-bell" /></a>
                  <div className="dropdown-menu dropdown-menu-right notification">
                    <div className="noti-head">
                      <h6 className="d-inline-block m-b-0">Notifications</h6>
                      <div className="float-right">
                        <a href="#!" className="m-r-10">mark as read</a>
                        <a href="#!">clear all</a>
                      </div>
                    </div>
                    <ul className="noti-body">
                      <li className="n-title">
                        <p className="m-b-0">NEW</p>
                      </li>
                      <li className="notification">
                        <div className="media">
                          <img className="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image" />
                          <div className="media-body">
                            <p><strong>John Doe</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10" />5 min</span></p>
                            <p>New ticket Added</p>
                          </div>
                        </div>
                      </li>
                      <li className="n-title">
                        <p className="m-b-0">EARLIER</p>
                      </li>
                      <li className="notification">
                        <div className="media">
                          <img className="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image" />
                          <div className="media-body">
                            <p><strong>Joseph William</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10" />10 min</span></p>
                            <p>Prchace New Theme and make payment</p>
                          </div>
                        </div>
                      </li>
                      <li className="notification">
                        <div className="media">
                          <img className="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image" />
                          <div className="media-body">
                            <p><strong>Sara Soudein</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10" />12 min</span></p>
                            <p>currently login</p>
                          </div>
                        </div>
                      </li>
                      <li className="notification">
                        <div className="media">
                          <img className="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image" />
                          <div className="media-body">
                            <p><strong>Joseph William</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10" />30 min</span></p>
                            <p>Prchace New Theme and make payment</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="noti-footer">
                      <a href="#!">show all</a>
                    </div>
                  </div>
                </div>
              </li> */}
              <li>
                <div className="dropdown drp-user">
                  <a
                    onClick={(e) => e.preventDefault()}
                    href="#!"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <i className="feather icon-user" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right profile-notification">
                    <div className="pro-head">
                      {profile ? (
                        <>
                          <img
                            src={profile.avatar}
                            style={{ width: 30, height: 30 }}
                            className="img-radius"
                            alt="UserProfileImage"
                          />
                          <span>{profile.name}</span>
                        </>
                      ) : null}

                      <a
                        href="#!"
                        onClick={(e) => {
                          e.preventDefault();
                          localStorage.clear();
                          history.replace("auth");
                          context.setValue("login", false);
                        }}
                        className="dud-logout"
                        title="Logout"
                      >
                        <i className="feather icon-log-out" />
                      </a>
                    </div>
                    <ul className="pro-body">
                      <li>
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/profile`);
                          }}
                          className="dropdown-item"
                        >
                          <i className="feather icon-user" /> Profile
                        </a>
                      </li>
                      <li>
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/laporan-penjualan`);
                          }}
                          className="dropdown-item"
                        >
                          <i className="feather icon-printer" /> Laporan Penjualan Kelas
                        </a>
                      </li>
                      <li>
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/change-password`);
                          }}
                          className="dropdown-item"
                        >
                          <i className="feather icon-lock" /> Ubah Password
                        </a>
                      </li>
                      {/* <li><a href="email_inbox.html" className="dropdown-item"><i className="feather icon-mail" /> My Messages</a></li>
                      <li><a href="auth-signin.html" className="dropdown-item"><i className="feather icon-lock" /> Lock Screen</a></li> */}
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <div className="pcoded-main-container">
        {!context.supportFirebase ? (
          <div
            class="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            <strong>Browser ini belum support!</strong> Beberapa fitur tidak
            bisa berjalan dibrowser ini
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : null}

        <div className="pcoded-wrapper container">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  {props.children}
                  {/* <div className="page-header">
                    <div className="page-block">
                      <div className="row align-items-center">
                        <div className="col-md-12">
                          <div className="page-header-title">
                            <h5 className="m-b-10">Horizontal Layout 2</h5>
                          </div>
                          <ul className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html"><i className="feather icon-home" /></a></li>
                            <li className="breadcrumb-item"><a href="#!">Page Layout</a></li>
                            <li className="breadcrumb-item"><a href="#!">Horizontal Layout 2</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* [ Main Content ] start */}
                  {/* <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5>Horizontal layout</h5>
                        </div>
                        <div className="card-body">
                          <p>In Horizontal Layout V2 - Navigation bar is set in a Horizontal way with fixed width container. It also showing/hidden while scrolling up/down.</p>
                          <div className="alert alert-info mb-0" role="alert">
                            <p className="mb-0">It is best suited for those applications where you required your navigation is set to be a Horizontal way with fixed width container.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* [ Main Content ] end */}
                  {/* [ Main Content ] start */}

                  {/* [ Main Content ] end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
