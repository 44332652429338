import React, { useContext } from "react";
import Template from "../../component/Template";
import { useForm } from "react-hook-form";
import { ContextProvider } from "../../context/BaseContext";
import MessageError from "../../component/MessageError";
import { decryptString, encryptString, setStorage } from "../../util/Functions";

export default function App() {
  const context = useContext(ContextProvider);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    // setRefresh(true)
    console.log("DATA ", data);
    let form = new FormData();
    form.append("old_password", data.old_password);
    form.append("new_password", data.new_password);

    let response = await context.postRequest("change-password-mentor", form);
    if (response) {
      console.log(response);
      if (response.status == true) {
        let auth = await localStorage.getItem("auth");
        if (auth) {
          auth = await decryptString(auth);
          auth = auth.split("|");
          let username = auth[0];
          let a = await encryptString(username + "|" + data.new_password);
          await setStorage("auth", a);
        }
        context.setNotification("success", response.message);
        reset();
      } else {
        // context.setNotification('danger',response.message)
        context.setNotification("danger", response.message);
      }
    }
  };
  const password = watch("new_password");
  return (
    <Template>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Ubah Password</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!">Ubah password</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h5>Ubah Password</h5>
          {/* <button onClick={()=>{history.push(`${path}/add`)}} className="btn btn-primary">Tambah Kuis</button> */}
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Password Lama
              </label>
              <div className="col-sm-4">
                <input
                  autocomplete="off"
                  type="password"
                  {...register("old_password", {
                    required: "Password lama harus diisi",
                  })}
                  className="form-control"
                  id="Password"
                  placeholder="Password Lama"
                />
                {errors.old_password ? (
                  <MessageError msg={errors.old_password.message} />
                ) : null}
              </div>
            </div>
            <hr />
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Password Baru
              </label>
              <div className="col-sm-4">
                <input
                  autocomplete="off"
                  type="password"
                  {...register("new_password", {
                    required: "Password baru harus diisi",
                  })}
                  className="form-control"
                  id="inputEmail3"
                  placeholder="Password Baru"
                />
                {errors.new_password ? (
                  <MessageError msg={errors.new_password.message} />
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Ulangi Password Baru
              </label>
              <div className="col-sm-4">
                <input
                  type="password"
                  {...register("renew_password", {
                    required: "Ulangi password baru",
                    validate: (val) => val == password || "Password tidak sama",
                  })}
                  className="form-control"
                  id="inputEmail3"
                  placeholder="Ulangi Password Baru"
                />
                {errors.renew_password ? (
                  <MessageError msg={errors.renew_password.message} />
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="inputEmail3"
                className="col-sm-2 col-form-label"
              ></label>
              <div className="col-sm-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: 150 }}
                >
                  Ubah Password
                </button>
                &nbsp;
                {/* <button className="btn btn-outline-primary" style={{width:100}}>Batal</button> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </Template>
  );
}
