import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import DataTable from "../../../../component/DataTable";
import { LoadingBall } from "../../../../component/Loading";
import {
  currencyFormat,
  setStorage,
  RenderStatusLiveKelas,
  removeSecTime,
} from "../../../../util/Functions";
import MessageError from "../../../../component/MessageError";
import moment from "moment";
import { ModalLiveDraft } from "./components";

export default function ListLiveKelas({
  context,
  is_live_class,
  getDataKelas,
  path,
  coverImage,
  titleKelas,
}) {
  const history = useHistory();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [liveKelasData, setliveKelasData] = useState([]);
  const [liveDataDraft, setLiveDataDraft] = useState([]);
  const [useZoomAccount, setuseZoomAccount] = useState(true);
  const [zoomAccount, seZoomAccount] = useState({
    zoom_email: "",
    zoom_type_license: null,
  });
  const typeLesensiZoom = [
    // { type: 1, label: "Basic" },
    { type: 2, label: "Licenced" },
    { type: 3, label: "On-prem" },
    // { type: 99, label: "None" },
  ];

  async function getDataLiveKelas() {
    setLoading(true);
    let response = await context.getRequest(`getLiveClassByClassID/${id}`);
    console.log("list live kelas : ", response);
    if (response) {
      setliveKelasData(response.data.data);
      setLoading(false);
    }
  }

  async function getProfile() {
    let profile = await JSON.parse(localStorage.profile_mentor);
    console.log(">>", profile);
    if (profile) {
      setuseZoomAccount(profile.zoom_use);
      seZoomAccount({
        zoom_email: profile.zoom_email,
        zoom_type_license: profile.zoom_type_license,
      });
    }
  }

  const onSubmit = async (data) => {
    // console.log({ data });
    // console.log(useZoomAccount);
    setDisableButton(true);
    if (useZoomAccount == null) {
      let response = await context.postRequest("updateMentor", {
        zoom_use: useZoomAccount,
      });
      console.log("?????", response);
      if (response) {
        context.setNotification("success", response.message);
        await setStorage("profile_mentor", JSON.stringify(response.data));
        context.setValue("profile", response.data);
        setDisableButton(false);
        document.getElementById("closeModalZoomAccount").click();
        getProfile();
      } else {
        setDisableButton(false);
        context.setNotification("danger", response.message);
        getProfile();
      }
    } else if (useZoomAccount == "self_account") {
      let form = {
        zoom_use: useZoomAccount,
        zoom_email: data.email_zoom,
        zoom_type_license: parseInt(data.lesensi_zoom),
      };
      let response = await context.postRequest("updateMentor", form);
      if (response) {
        console.log(response);
        if (response.status == true) {
          context.setNotification("success", response.message);
          await setStorage("profile_mentor", JSON.stringify(response.data));
          context.setValue("profile", response.data);
          getProfile();
          setDisableButton(false);
          document.getElementById("closeModalZoomAccount").click();
        } else {
          setDisableButton(false);
          context.setNotification("danger", response.message);
          getProfile();
        }
      }
    }
  };

  function renderLesensi(type) {
    // 0:basic, 1:licenced, 3:on-prem, 99:none
    let renderType = "-";
    switch (type) {
      case 1:
        renderType = "Basic";
        break;
      case 2:
        renderType = "Licenced";
        break;
      case 3:
        renderType = "On-prem";
        break;
      case 99:
        renderType = "None";
        break;

      default:
        break;
    }
    return renderType;
  }

  function checkAccountZoom(event) {
    console.log(event.target.checked);
    if (zoomAccount.zoom_use == null && event.target.checked == true) {
      hendleChangeStatus(event);
    } else if (
      zoomAccount.zoom_type_license == null &&
      event.target.checked == true
    ) {
      swal({
        title: "Email zoom belum diisi",
        text: "untuk mengaktifkan live kelas anda harus mendaftarkan email zoom anda, tekan lanjut untuk mendaftarkan email zoom anda",
        icon: "warning",
        dangerMode: true,
        buttons: ["Tidak jadi", "Lanjut"],
      }).then(async (willNext) => {
        if (willNext) {
          document.getElementById("buttonModalZoomAccount").click();
        }
      });
    } else {
      hendleChangeStatus(event);
    }
  }

  async function hendleChangeStatus(event) {
    let form = new FormData();
    switch (event.target.checked) {
      case true:
        form.append("is_live_class", true);
        let res = await context.postRequest(`MentorUpdateKelas/${id}`, form);
        if (res) getDataKelas();
        break;
      case false:
        form.append("is_live_class", false);
        let ress = await context.postRequest(`MentorUpdateKelas/${id}`, form);
        if (ress) getDataKelas();
        break;
      default:
        break;
    }
  }

  async function deleteLiveKelas(id) {
    swal({
      title: "Anda yakin?",
      text: "Jika anda menghapus live kelas ini, kelas dan semua jadwal akan terhapus",
      icon: "warning",
      dangerMode: true,
      buttons: ["Tidak jadi", "Ya, saya akan hapus!"],
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await context.postRequest(`live-class-delete`, { id });
        if (response) {
          swal("Hapus kelas berhasil dilakukan", {
            icon: "success",
          });
          getDataLiveKelas();
        }
      }
    });
  }

  const RenderItem = ({ val, index }) => {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{val.title ? val.title : "-"}</td>
        <td>
          {val.date_start == val.date_end ? (
            moment(val.date_start).format("DD MMM YYYY")
          ) : (
            <>
              <p className="m-0">
                {moment(val.date_start).format("DD MMM YYYY")} s/d
              </p>
              <p className="m-0">
                {moment(val.date_end).format("DD MMM YYYY")}
              </p>
            </>
          )}
        </td>
        <td>{val.min_user} Orang</td>
        <td>{val.jml_pendaftar ? val.jml_pendaftar : 0} Orang</td>
        {/* 0:Draft 1:Publish 2:In Review 11:ongoing 12:Done 4:rejected */}
        <td>
          <RenderStatusLiveKelas status={val.status} />
        </td>
        <td>
          {val.status_temp == 0 || val.status_temp == 2 ? (
            <button
              onClick={async () => {
                let response = await context.getRequest(
                  `getDetailLiveClass/${val.id}`
                );
                if (response) {
                  console.log("RESPONSE", response);
                  setLiveDataDraft(response.data.data.liveClassTemp[0]);
                }
              }}
              type="button"
              data-toggle="modal"
              data-target="#exampleModal"
              className={
                val.status_temp == 0
                  ? "btn badge bg-warning text-light"
                  : "btn badge bg-info text-light"
              }
            >
              {val.status_temp == 0 ? "Draft" : "In Review"}
            </button>
          ) : (
            <RenderStatusLiveKelas status={val.status_temp} />
          )}
        </td>
        <td>{`Rp ${currencyFormat(val.price)}`}</td>
        <td style={{ minWidth: "120px" }}>
          <i className="fas fa-ellipsis-h" />
          <div className="overlay-edit">
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `${path}/live-zoom-class/${val.id}`,
                  state: { coverImage, titleKelas },
                });
              }}
              className="btn btn-icon btn-info"
            >
              <i className="feather icon-eye" />
            </a>
            {val.status == 0 ||
            val.status == 1 ||
            val.status == 2 ||
            val.status == 4 ? (
              <>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `${path}/edit-live-class/${val.id}`,
                    });
                  }}
                  className="btn btn-icon btn-warning"
                >
                  <i className="feather icon-edit-2" />
                </a>
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteLiveKelas(val.id);
                  }}
                  type="button"
                  className="btn btn-icon btn-danger"
                >
                  <i className="feather icon-trash-2" />
                </a>
              </>
            ) : null}
          </div>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      getDataLiveKelas();
      getProfile();
    }
    return () => {
      isSubscribe = false;
    };
  }, []);
  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <div className="">
          <button
            onClick={() => {
              history.push(`${path}/new-live-class`);
            }}
            className="btn btn-primary"
          >
            <i className="fa fa-plus mr-1" />
            Tambah Live Kelas
          </button>
        </div>
        <div className="row border p-2">
          <div>
            {useZoomAccount == "self_account" ? (
              <>
                <tr>
                  <td>Email zoom</td>
                  <td className="p-1">:</td>
                  <td style={{ minWidth: "120px" }}>
                    {zoomAccount.zoom_email ? zoomAccount.zoom_email : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Lesensi</td>
                  <td className="p-1">:</td>
                  <td style={{ minWidth: "120px" }}>
                    {renderLesensi(zoomAccount.zoom_type_license)}
                  </td>
                </tr>
              </>
            ) : (
              <p
                className="mb-2 mt-2"
                style={{ fontSize: "14px", fontWeight: 500 }}
              >
                Menggunakan akun zoom wakool academy
              </p>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-center ml-3">
            <button
              id="buttonModalZoomAccount"
              type="button"
              class="btn btn-sm btn-warning"
              data-toggle="modal"
              data-target="#modalZoomAccount"
              onClick={() => {
                setValue("email_zoom", zoomAccount.zoom_email);
                setValue("lesensi_zoom", zoomAccount.zoom_type_license);
              }}
            >
              ubah
            </button>
          </div>
        </div>
        <div className="">
          <div className="form-group mb-0">
            <div className="switch switch-primary d-inline m-r-10">
              <input
                onChange={(event) => {
                  // hendleChangeStatus(event);
                  checkAccountZoom(event);
                }}
                type="checkbox"
                id="switch-live"
                checked={is_live_class}
              />
              <label htmlFor="switch-live" className="cr" />
            </div>
            <label
              style={is_live_class ? { color: "green" } : { color: "red" }}
            >
              {is_live_class ? "Kelas Live Aktif" : "Kelas Live Tidak Aktif"}
            </label>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12">
            <div className="user-profile-list">
              <div className="dt-responsive table-responsive">
                {loading ? (
                  <LoadingBall />
                ) : (
                  <DataTable
                    className={"table table-striped table-bordered nowrap"}
                    data={liveKelasData}
                    totalRows={liveKelasData.length}
                    show={[10, 20, 30, 40, 50]}
                    onChangeSearch={(val) => {
                      console.log(val);
                    }}
                    onChangeLimit={(val) => {
                      console.log(val);
                    }}
                    onChangePage={(val) => {
                      console.log(val);
                    }}
                    column={[
                      "No",
                      "Judul",
                      "Tanggal",
                      "Min Pendaftar",
                      "Pendaftar",
                      "Status",
                      "Status Perubahan",
                      "Harga",
                      "Action",
                    ]}
                    renderItem={(item, i) => (
                      <RenderItem val={item} key={i} index={i} />
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL DATA DRAFT */}
      <ModalLiveDraft
        liveDataDraft={liveDataDraft}
        coverImage={coverImage}
        titleKelas={titleKelas}
        context={context}
      />
      {/* END MODAL DATA DRAFT */}
      <div
        class="modal fade"
        id="modalZoomAccount"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="modalZoomAccountLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div class="modal-header">
                <h5 class="modal-title" id="modalZoomAccountLabel">
                  Zoom Account
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  disabled={disableButton}
                  onClick={() => getProfile()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Akun Zoom</label>
                  <div className="col-sm-9">
                    <div>
                      <div className="form-check">
                        <input
                          checked={useZoomAccount == null}
                          onChange={() => {
                            reset();
                            setuseZoomAccount(null);
                          }}
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          Memakai akun zoom wakool academy
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          checked={useZoomAccount == "self_account"}
                          onChange={() => {
                            reset();
                            setuseZoomAccount("self_account");
                          }}
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          Memakai akun zoom pribadi
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {useZoomAccount == "self_account" && (
                  <>
                    <div className="form-group row">
                      <label
                        htmlFor="email_zoom"
                        className="col-sm-3 col-form-label"
                      >
                        Email Zoom
                      </label>
                      <div className="col-sm-9">
                        <input
                          {...register("email_zoom", {
                            required: "Email tidak boleh kosong",
                          })}
                          type="text"
                          className="form-control"
                          id="email_zoom"
                          placeholder="Masukkan email zoom anda"
                        />
                        {errors.email_zoom && (
                          <MessageError msg={errors.email_zoom.message} />
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Lisensi Zoom
                      </label>
                      <div className="col-sm-9">
                        <select
                          {...register("lesensi_zoom", {
                            required: "Pilih salah satu lesensi",
                          })}
                          className="form-control"
                        >
                          <option value="">- Pilih lisensi -</option>
                          <>
                            {typeLesensiZoom.map((item, idx) => (
                              <option key={idx} value={item.type}>
                                {item.label}
                              </option>
                            ))}
                          </>
                        </select>
                        {errors.lesensi_zoom && (
                          <MessageError msg={errors.lesensi_zoom.message} />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div class="modal-footer">
                <button
                  onClick={() => getProfile()}
                  id="closeModalZoomAccount"
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  disabled={disableButton}
                >
                  Close
                </button>
                <button
                  disabled={disableButton}
                  type="submit"
                  class="btn btn-primary"
                >
                  {disableButton && (
                    <div
                      style={{ width: "20px", height: "20px" }}
                      className="spinner-border text-light mr-1"
                    />
                  )}
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
