import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BreadCrumb } from "../../../../component/breadcrumb";
import { ContextProvider } from "../../../../context/BaseContext";
import { RenderDetailLive, RenderJadwal } from "./components";

export default function LiveZoom() {
  const location = useLocation();
  const context = useContext(ContextProvider);
  const { id, liveId } = useParams();

  const [loading, setLoading] = useState(false);
  const [errorCover, setErrorCover] = useState(false);
  const [liveKelas, setLiveKelas] = useState([]);
  const [listJadwal, setListJadwal] = useState([]);

  async function getDataKelas() {
    setLoading(true);
    let response = await context.getRequest(`getDetailLiveClass/${liveId}`);
    console.log("RESPONSE", response);
    if (response) {
      setLoading(false);
      let liveKelas = [];
      let listJadwal = [];
      if (
        response.data.data.liveClass.length > 0 &&
        response.data.data.liveClassTemp.length > 0
      ) {
        liveKelas = response.data.data.liveClass[0];
        listJadwal = response.data.data.liveClass[0].webinar;
      } else if (response.data.data.liveClass.length > 0) {
        liveKelas = response.data.data.liveClass[0];
        listJadwal = response.data.data.liveClass[0].webinar;
      } else if (response.data.data.liveClassTemp.length > 0) {
        liveKelas = response.data.data.liveClassTemp[0];
        listJadwal = response.data.data.liveClassTemp[0].webinars_temp;
      }
      // console.log("????",{liveKelas, listJadwal});
      // console.log("RESPONSE", response.data.data);
      setLiveKelas(liveKelas);
      setListJadwal(listJadwal);
    }
  }

  useEffect(() => {
    getDataKelas();
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Live Zoom</h5>
              </div>
              <BreadCrumb
                pageName={"Live Zoom"}
                links={[
                  { url: "/kelas", name: "Kelas" },
                  { url: "", name: "Detail Kelas" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title p-2">Detail Kelas</h5>
              <div
                className="text-center"
                style={{ backgroundColor: "whitesmoke" }}
              >
                <img
                  src={
                    errorCover
                      ? process.env.PUBLIC_URL + `/assets/images/noImage.png`
                      : location.state?.coverImage
                  }
                  alt="cover-class"
                  className="img-fluid"
                  style={{ height: "150px" }}
                  onError={() => setErrorCover(true)}
                />
              </div>
              <tr>
                <td className="p-2">Nama Kelas</td>
                <td>:</td>
                <td className="p-2">{location.state?.titleKelas}</td>
              </tr>
              {liveKelas.length != 0 && <RenderDetailLive item={liveKelas} />}
            </div>
          </div>
        </div>
        <div className="col-sm-8">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title p-2">Jadwal Live</h5>
              <div id="list-schedule">
                {listJadwal.length == 0 ? (
                  <div className="text-center">
                    <p style={{ fontStyle: "italic" }}>Tidak memiliki jadwal</p>
                  </div>
                ) : (
                  <>
                    {listJadwal.map((item, idx) => (
                      <RenderJadwal key={idx} val={item} context={context} />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
