import React from "react";

const LoadingBall = () => {
  return (
    <div className="col-12 text-center">
      <div className="loadingio-spinner-ball-ooxn6yt3to">
        <div className="ldio-r7hrik64b6a">
          <div></div>
        </div>
      </div>
      <h5>Loading...</h5>
    </div>
  );
};

export { LoadingBall };
