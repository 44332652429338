import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import DataTable from "../../../../../component/DataTable";
import { LoadingBall } from "../../../../../component/Loading";
import MessageError from "../../../../../component/MessageError";

export default function FilePendukungGlobal({ path, context }) {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [listFile, setListFile] = useState([]);

  async function getFileGlobal() {
    setLoading(true);
    try {
      let response = await context.getRequest(`content-file?class_id=${id}`);
    //   console.log("RESPONSE: ", response);
      if (response) {
        setLoading(false);
        setListFile(response.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.log("ERROR", error);
    }
  }

  function deleteFile(id) {
    swal({
      title: "Anda yakin?",
      text: "Jika anda menghapus file ini, file akan terhapus",
      icon: "warning",
      dangerMode: true,
      buttons: ["Tidak jadi", "Ya, saya akan hapus!"],
    }).then(async (willDelete) => {
      if (willDelete) {
        let form = new FormData();
        form.append("id", id);
        let response = await context.postRequest(`content-file-delete`, form);
        if (response) {
          swal("Hapus file berhasil dilakukan", {
            icon: "success",
          });
          getFileGlobal();
        }
      }
    });
  }

  const RenderItem = ({ val }) => {
    const [showFormEdit, setshowFormEdit] = useState(false);
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
    } = useForm();

    const onSubmit = async (data) => {
      //   console.log(data, val.id, val.content_id);
      let form = new FormData();
      form.append("id", val.id);
      form.append("class_id", id);
      form.append("nama_file", data.namaFile);

      let response = await context.postRequest("content-file-update", form);
      if (response) {
        context.setNotification("success", "Nama file berhasil diubah");
        reset();
        setshowFormEdit(false);
        getFileGlobal();
      } else {
        context.setNotification("danger", "Nama file gagal diubah");
      }
    };

    return (
      <tr>
        <td>
          {showFormEdit ? (
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-sm-8">
                <input
                  {...register("namaFile", {
                    required: "nama file harus diisi",
                  })}
                  className="ml-2 form-control form-control-sm"
                  type="text"
                />
                {errors.namaFile && (
                  <MessageError msg={errors.namaFile.message} />
                )}
              </div>
              <div className="col-sm-4">
                <button
                  className="mr-1 btn btn-success btn-sm"
                  style={{ borderRadius: "50%" }}
                  type="submit"
                >
                  <i style={{ color: "white" }} className="fas fa-check" />
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  style={{ borderRadius: "50%" }}
                  type="button"
                  onClick={() => {
                    setshowFormEdit(false);
                    reset();
                  }}
                >
                  <i
                    style={{ color: "white", width: "14px" }}
                    className="fas fa-times"
                  />
                </button>
              </div>
            </form>
          ) : (
            <>{val.nama_file}</>
          )}
        </td>
        <td>
          <i className="fas fa-ellipsis-h" />
          {!showFormEdit && (
            <div className="overlay-edit">
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setshowFormEdit(true);
                  setValue("namaFile", val.nama_file);
                }}
                className="btn btn-icon btn-warning"
              >
                <i className="feather icon-edit-2" />
              </a>

              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  deleteFile(val.id);
                }}
                type="button"
                className="btn btn-icon btn-danger"
              >
                <i className="feather icon-trash-2" />
              </a>
            </div>
          )}
        </td>
      </tr>
    );
  };

  useEffect(() => {
    getFileGlobal();
  }, []);
  return (
    <div className="card">
      <div className="card-header">
        <button
          onClick={() => {
            history.push(`${path}/add-file`);
          }}
          className="btn btn-primary"
        >
          <i className="fa fa-plus mr-1" />
          Tambah File
        </button>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12">
            <div className="user-profile-list">
              <div className="dt-responsive table-responsive">
                {loading ? (
                  <LoadingBall />
                ) : (
                  <DataTable
                    className={"table dataTable-style nowrap"}
                    data={listFile}
                    totalRows={listFile.length}
                    show={[10, 20, 30, 40, 50]}
                    onChangeSearch={(val) => {
                      console.log(val);
                    }}
                    onChangeLimit={(val) => {
                      console.log(val);
                    }}
                    onChangePage={(val) => {
                      console.log(val);
                    }}
                    column={["Nama File", "Action"]}
                    renderItem={(item, i) => <RenderItem val={item} key={i} />}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
