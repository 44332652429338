import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import MessageError from "../../../component/MessageError";
import { ContextProvider } from "../../../context/BaseContext";
import { BreadCrumb } from "../../../component/breadcrumb";

export default function EditContentKuis() {
  const history = useHistory();
  const { contentId } = useParams();
  const location = useLocation();
  const context = useContext(ContextProvider);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [sectionClass] = useState(
    location.state ? location.state.allSections : []
  );

  const [loading, setLoading] = useState(false);
  const [listKuis] = useState(location.state ? location.state.listKuis : []);

  const onSubmit = async (data) => {
    // console.log(data);
    setLoading(true);
    let form = new FormData();
    form.append("title", data.judul);
    form.append("content_type_id", "2");
    form.append("contents_url", data.kuis);
    form.append("durasi", data.durasi);
    form.append("section_id", data.section);
    form.append("urutan", data.urutan);

    let response = await context.postRequest(
      `/updateContent/${contentId}`,
      form,
      "form"
    );
    if (response) {
      setLoading(false);
      history.goBack();
      context.setNotification("success", "Materi berhasil diubah");
      reset();
    } else {
      setLoading(false);
    }
  };

  function setDefaultValue() {
    setValue("judul", location.state?.content.title);
    setValue("section", location.state?.content.section_id);
    setValue("durasi", location.state?.content.durasi);
    setValue("urutan", location.state?.content.urutan);
    setValue("kuis", location.state?.content.quiz?.id);
  }

  useEffect(() => {
    // getData();
    setDefaultValue();
    // console.log(location);
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">
                  Edit Materi Kuis : {location.state?.content.title}
                </h5>
              </div>
              <BreadCrumb
                pageName={"Edit Materi Kuis"}
                links={[
                  { url: "/kelas", name: "Kelas" },
                  { url: "", name: "Detail Kelas" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mt-2">
              <div className="col-lg-12">
                <div className="form-group row">
                  <label htmlFor="judul" className="col-sm-2 col-form-label">
                    Judul
                  </label>
                  <div className="col-sm-10">
                    <input
                      {...register("judul", { required: "Judul harus diisi" })}
                      type="text"
                      className="form-control"
                      id="judul"
                      placeholder="Masukkan judul materi"
                    />
                    {errors.judul && (
                      <MessageError msg={errors.judul.message} />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">Kuis</label>
                  <div className="col-sm-8">
                    <select
                      {...register("kuis", {
                        required: "Pilih salah satu section",
                      })}
                      className="form-control"
                    >
                      <option value="">- Pilih kuis -</option>
                      {listKuis.length != 0 ? (
                        <>
                          {listKuis.map((item, idx) => (
                            <option key={idx} value={item.id}>
                              {item.title}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option disabled>Anda belum memiliki kuis</option>
                      )}
                    </select>
                    {errors.kuis && <MessageError msg={errors.kuis.message} />}
                  </div>
                  <div className="col-sm-2">
                    <button className="btn btn-primary">
                      <a style={{ color: "white" }} href="/kuis">
                        <i className="feather icon-settings mr-1" /> Kelola Kuis
                      </a>
                    </button>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">Section</label>
                  <div className="col-sm-10">
                    <select
                      {...register("section", {
                        required: "Pilih salah satu section",
                      })}
                      className="form-control"
                    >
                      <option value="">- Pilih section -</option>
                      {sectionClass.length != 0 ? (
                        <>
                          {sectionClass.map((item, idx) => (
                            <option key={idx} value={item.id}>
                              {item.title}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option disabled>Section tidak ditemukan</option>
                      )}
                    </select>
                    {errors.section && (
                      <MessageError msg={errors.section.message} />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="durasi" className="col-sm-2 col-form-label">
                    Durasi (menit)
                  </label>
                  <div className="col-sm-10">
                    <input
                      onInput={(e) =>
                        (e.target.value =
                          !!e.target.value && Math.abs(e.target.value) >= 0
                            ? Math.abs(e.target.value)
                            : null)
                      }
                      {...register("durasi", {
                        required: "Durasi kuis harus diisi",
                      })}
                      type="text"
                      className="form-control"
                      id="durasi"
                      placeholder="Masukkan durasi materi"
                    />
                    {errors.durasi && (
                      <MessageError msg={errors.durasi.message} />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="urutan" className="col-sm-2 col-form-label">
                    No Urutan
                  </label>
                  <div className="col-sm-10">
                    <input
                      onInput={(e) =>
                        (e.target.value =
                          !!e.target.value && Math.abs(e.target.value) >= 0
                            ? Math.abs(e.target.value)
                            : null)
                      }
                      {...register("urutan", {
                        required: "No urutan harus diisi",
                      })}
                      type="text"
                      className="form-control"
                      id="urutan"
                      placeholder="Masukkan no urutan materi"
                    />
                    {errors.urutan && (
                      <MessageError msg={errors.urutan.message} />
                    )}
                  </div>
                </div>
                <div className="form-group row mt-5">
                  <label className="col-sm-2 col-form-label" />
                  <div className="col-sm-10">
                    <button
                      type="button"
                      onClick={() => {
                        history.goBack();
                      }}
                      className="btn btn-outline-secondary"
                    >
                      <i className="fas fa-arrow-left mr-1" />
                      Kembali
                    </button>
                    <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-primary ml-2"
                    >
                      {loading ? (
                        <div
                          style={{ width: "20px", height: "20px" }}
                          className="spinner-border text-light mr-1"
                        />
                      ) : (
                        <i className="fas fa-save mr-1" />
                      )}
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
