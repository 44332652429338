import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import MessageError from "../../component/MessageError";
import { ContextProvider } from "../../context/BaseContext";

export default function App({ path }) {
  const context = useContext(ContextProvider);
  const history = useHistory();
  const location = useLocation();

  const [kelas, setKelas] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    setRefresh(true);
    // console.log("DATA ", data);
    let form = new FormData();
    form.append("title", data.title);
    form.append("time", data.time);
    form.append("description", data.description);
    form.append("content_id", data.content_id == "" ? 0 : data.content_id);
    // form.append('questions[]',"")
    let response = await context.postRequest("addQuiz", form);
    if (response) {
      // console.log(response);
      setRefresh(false);
      // location.getData();
      context.setNotification("success", "Kuis berhasil ditambahkan");
      // history.replace(`${path}/add-soal/${response.data.id}`);
      history.replace({
        pathname: `${path}/add-soal/${response.data.id}`,
        getData: location.getData.bind(this),
      });
    }
  };
  async function getKelas() {
    let response = await context.getRequest("getKelasByMentorWithoutPaginate");
    if (response) {
      // console.log(response.data.data);
      setKelas(response.data.data);
    }
  }
  useEffect(() => {
    getKelas();
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Tambah Kuis</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/kuis">Kuis</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!">Tambah Kuis</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Judul
              </label>
              <div className="col-sm-6">
                <input
                  {...register("title", { required: "Judul harus diisi" })}
                  type="text"
                  className="form-control"
                  id="inputEmail3"
                  placeholder="Masukkan judul kuis"
                />
                {errors.title ? (
                  <MessageError msg={errors.title.message} />
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Waktu Pengerjaan (menit)
              </label>
              <div className="col-sm-6">
                <input
                  type="number"
                  {...register("time", {
                    required: "Waktu harus diisi",
                    pattern: {
                      value: "^-?[0-9]d*.?d*$",
                      message: "harus angka",
                    },
                  })}
                  className="form-control"
                  id="inputEmail3"
                  placeholder="Waktu Pengerjaan (menit)"
                />
                {errors.time ? (
                  <MessageError msg={errors.time.message} />
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Deskripsi
              </label>
              <div className="col-sm-6">
                <textarea
                  {...register("description", {
                    required: "Deskripsi harus diisi",
                  })}
                  type="text"
                  className="form-control"
                  id="inputEmail3"
                  placeholder="Deskripsi"
                />
                {errors.description ? (
                  <MessageError msg={errors.description.message} />
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Konten
              </label>
              <div className="col-sm-6">
                <select {...register("content_id")} className="form-control">
                  <option value="">- Pilih Konten -</option>
                  {kelas.map((val) => {
                    return (
                      <optgroup label={val.title}>
                        {val.sections.map((v1) => {
                          return v1.contents.map((v2) => {
                            return (
                              <option value={v2.id}>
                                {v1.title + " - " + v2.title}
                              </option>
                            );
                          });
                        })}
                      </optgroup>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group row mt-5">
              <label className="col-sm-2 col-form-label" />
              <div className="col-sm-10">
                <button
                  type="button"
                  onClick={() => {
                    history.goBack();
                  }}
                  className="btn btn-outline-secondary"
                >
                  <i className="fas fa-arrow-left mr-1" />
                  Kembali
                </button>
                <button type="submit" className="btn btn-primary ml-2">
                  {refresh ? (
                    <div
                      style={{ width: "20px", height: "20px" }}
                      className="spinner-border text-light mr-1"
                    />
                  ) : (
                    <i className="fas fa-save mr-1" />
                  )}
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
