import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import Template from "../../component/Template";
import AddKelas from "./addKelas";
import EditKelas from './editKelas'
import RenderIndex from "./renderIndex";
import DetailKelas from './DetailKelas/detalKelas'

export default function App() {
  let { path, url } = useRouteMatch();

  return (
    <Template>
      <Switch>
        <Route exact path={`${path}`}>
          <RenderIndex path={path} />
        </Route>
        <Route path={`${path}/add`}>
          <AddKelas />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <EditKelas />
        </Route> 
        <Route path={`${path}/detail/:id`}>
          <DetailKelas />
        </Route>
      </Switch>
    </Template>
  );
}
