import React from "react";

// export const RenderButtonStatus = ({ status, hendleAjukanKelas, namaKelas, idKelas }) => {
//   if (status == 0) {
//     //draft
//     return (
//       <button
//         onClick={() => hendleAjukanKelas(namaKelas, idKelas)}
//         title="Ajukan kelas"
//         className="btn btn-info rounded-circle wid-50 hei-50 p-2"
//         style={{ boxShadow: "0 0 0 5px #fff" }}
//       >
//         <i
//           className="fas fa-arrow-up text-light"
//           style={{ fontSize: "x-large" }}
//         ></i>
//       </button>
//     );
//   } else if (status == 1) {
//     //diajukan
//     return (
//       <button
//         title="Mohon bersabar kelas sedang proses pengajuan penerbitan"
//         className="btn btn-info rounded-circle wid-50 hei-50 p-2"
//         style={{ boxShadow: "0 0 0 5px #fff", opacity: "0.8" }}
//         disabled
//       >
//         <i
//           className="fas fa-arrow-up text-light"
//           style={{ fontSize: "x-large" }}
//         ></i>
//       </button>
//     );
//   } else if (status == 2) {
//     //success
//     return (
//       <button
//         title="Yeay, kelasmu sudah diterbitkan"
//         className="btn btn-success rounded-circle wid-50 hei-50 p-2"
//         style={{ boxShadow: "0 0 0 5px #fff", opacity: "0.8" }}
//         disabled
//       >
//         <i
//           className="fas fa-check text-light"
//           style={{ fontSize: "x-large" }}
//         ></i>
//       </button>
//     );
//   } else {
//     //ditolak
//     return (
//       <button
//         title="Mohon maaf kelasmu tidak lolos syarat dan ketentuan kami"
//         className="btn btn-danger rounded-circle wid-50 hei-50 p-2"
//         style={{ boxShadow: "0 0 0 5px #fff", opacity: "0.8" }}
//         disabled
//       >
//         <i
//           className="fas fa-times text-light"
//           style={{ fontSize: "x-large" }}
//         ></i>
//       </button>
//     );
//   }
// };

export const RenderStatus = ({ status }) => {
  if (status == 0) {
    return (
      <p className="mb-0 text-light badge badge-pill badge-warning">Draft</p>
    );
  } else if (status == 1) {
    return (
      <p className="mb-0 text-light badge badge-pill badge-info">Diajukan</p>
    );
  } else if (status == 2) {
    return (
      <p className="mb-0 text-light badge badge-pill badge-success">
        Diterbitkan
      </p>
    );
  } else {
    return (
      <p className="mb-0 text-light badge badge-pill badge-danger">Ditolak</p>
    );
  }
};

export const RenderStatusClasses = ({
  draft,
  diajukan,
  diterbitkan,
  ditolak,
}) => {
  return (
    <div className="row">
      <div className="col-md-6 col-xl-3">
        <div className="card widget-statstic-card">
          <div className="card-body">
            <div className="card-header-left mb-3">
              <h5 className="mb-0">Total Kelas Draft</h5>
            </div>
            <i className="fas fa-pen st-icon bg-warning"></i>
            <div className="text-left">
              <h3 className="d-inline-block">{draft}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-xl-3">
        <div className="card widget-statstic-card">
          <div className="card-body">
            <div className="card-header-left mb-3">
              <h5 className="mb-0">Total Kelas Diajukan</h5>
            </div>
            <i className="fas fa-arrow-up st-icon bg-info"></i>
            <div className="text-left">
              <h3 className="d-inline-block">{diajukan}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-xl-3">
        <div className="card widget-statstic-card">
          <div className="card-body">
            <div className="card-header-left mb-3">
              <h5 className="mb-0">Total Kelas Diterbitkan</h5>
            </div>
            <i className="fas fa-check st-icon bg-success"></i>
            <div className="text-left">
              <h3 className="d-inline-block">{diterbitkan}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-xl-3">
        <div className="card widget-statstic-card">
          <div className="card-body">
            <div className="card-header-left mb-3">
              <h5 className="mb-0">Total Kelas Ditolak</h5>
            </div>
            <i className="fas fa-times st-icon bg-danger"></i>
            <div className="text-left">
              <h3 className="d-inline-block">{ditolak}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
