import React from "react";
import { useHistory, useParams } from "react-router-dom";
import IframeZoom from "./IframeZoom";

export default function FullscreenZoom() {
  const { token } = useParams();
  const history = useHistory();

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {/* <div style={{ background: "black" }} className="text-right p-2">
        <button
          onClick={() => history.goBack()}
          type="button"
          className="btn btn-outline-light mr-2"
        >
          <i className="fas fa-compress"></i> Exit fullscreen
        </button>
      </div> */}
      <IframeZoom token={token} />
    </div>
  );
}
