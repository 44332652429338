import React from "react";
import { BreadCrumb } from "../../../../component/breadcrumb";

const dummylist = [1, 2, 3, 4, 5, 6];

export default function DisscussPrivate() {
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Diskusi Private Kelas</h5>
              </div>
              <BreadCrumb
                pageName={"Diskusi Private Kelas"}
                links={[
                  { url: "/kelas", name: "Kelas" },
                  { url: "", name: "Detail Kelas" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title p-2">Detail Pengajuan Kelas</h5>
              <tr>
                <td className="p-2">Dari</td>
                <td>:</td>
                <td className="p-2">Nama Pengaju kelas</td>
              </tr>
              <tr>
                <td className="p-2">Metode</td>
                <td>:</td>
                <td className="p-2">Offline</td>
              </tr>
              <tr>
                <td className="p-2">Tanggal Permintaan</td>
                <td>:</td>
                <td className="p-2">2 Agustus 2021</td>
              </tr>
              <tr>
                <td className="p-2">Kuantitas</td>
                <td>:</td>
                <td className="p-2">10 Orang</td>
              </tr>
              <tr>
                <td className="p-2">Alamat</td>
                <td>:</td>
                <td className="p-2">
                  Metro Trade Center Blok J9, Jl. Soekarno Hatta 590, Bandung,
                  Jawa Barat 40286 Indonesia
                </td>
              </tr>
              <tr>
                <td className="p-2">Imformasi Tambahan</td>
                <td>:</td>
                <td className="p-2"></td>
              </tr>
              <hr />
              <button type="button" className="btn btn-primary col-12 mb-2">
                Batalkan
              </button>
              <button type="button" className="btn btn-success col-12 mb-2">
                Setuju
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-8">
          <div className="card">
            <div className="card-body pt-4">
              <form>
                <div className="text-right mb-2">
                  <button type="button" className="btn btn-sm btn-primary">
                    Kirim Balasan
                  </button>
                </div>
                <textarea
                  rows="4"
                  className="form-control"
                  placeholder="Tulis balasan disini"
                />
              </form>
              <hr />
              <div style={{ height: "500px", overflowX: "scroll" }}>
                {dummylist.map((item, idx) => {
                  return (
                    <>
                      <div className="p-2 mt-3 mb-3" key={idx}>
                        <p
                          className="mb-1"
                          style={{ fontStyle: "italic", fontSize: "14px" }}
                        >
                          20 Juli 2021 - 12.00
                        </p>
                        <h5 className="mb-1">Dari Admin</h5>
                        <p className="mt-4">
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Magni ad, sint facere quisquam voluptatem
                          corporis commodi tenetur accusantium vero. Culpa,
                          ipsum laborum similique blanditiis nihil dolore
                          perspiciatis maxime dolor aperiam. Lorem ipsum dolor
                          sit amet consectetur adipisicing elit. Dolorum
                          provident excepturi tenetur ducimus natus. Saepe
                          voluptas tempora eaque debitis praesentium, ipsum a
                          optio facere dolor, magnam, adipisci amet fugit modi!
                        </p>
                      </div>
                      <hr />
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
