import React, { useEffect, useState, useContext } from "react";
import Template from "../../component/Template";
import { ContextProvider } from "../../context/BaseContext";
import moment from "moment";
import "moment/locale/id";
import { LoadingBall } from "../../component/Loading";
import DataKosong from "../../component/DataKosong";

export default function App() {
  const context = useContext(ContextProvider);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    let response = await context.getRequest(`mentor-notifikasi`);
    if (response) {
      // console.log(response.data.data);
      setData(response.data.data);
      setLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <Template>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Notifikasi</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!">Notifikasi</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card latest-update-card">
        <div className="card-header">
          <h5>Notifikasi</h5>
        </div>
        <div className="card-body">
          {loading ? (
            <LoadingBall />
          ) : (
            <>
              {data.length != 0 ? (
                <div className="latest-update-box">
                  {data.map((val, i) => {
                    return val.data.map((v, ii) => {
                      var dd = v.created_at.split(" ");
                      var ico = "feather icon-star bg-twitter";
                      if (v.type == "payment") {
                        ico = "feather icon-check-circle bg-facebook";
                      }
                      return (
                        <div key={ii} className="row p-t-30 p-b-30">
                          <div className="col-auto text-right update-meta">
                            <p className="text-muted m-b-0 d-inline-flex">
                              {moment(dd[0]).format("DD MMM YYYY")}
                            </p>
                            <i className={`${ico}  update-icon`} />
                          </div>
                          <div className="col">
                            <a href="#!">{/* <h6>+ 1652 Followers</h6> */}</a>
                            <p className="text-muted m-b-0">
                              {v.name} {v.concat}
                            </p>
                          </div>
                        </div>
                      );
                    });
                  })}
                </div>
              ) : (
                <DataKosong text="Opss, anda belum memiliki notifikasi apapun" />
              )}
            </>
          )}
        </div>
      </div>
    </Template>
  );
}
