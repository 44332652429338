import React, { useContext, useEffect, useState } from 'react';
import MessageItem from './message-item';

const MessageList = ({ setId, data }) => {
  return (
    <ul
      className="list-unstyled mb-0 bg-light"
      style={{
        height: "100%",
        overflowY: "scroll"
      }}
    >
      {data.length !== 0 ?
        data.map((message) =>
          <MessageItem
          key={message.id} 
          message={message} 
          setId={setId}
          // setChatToDisplay={setChatToDisplay}
          />
        )
        : null
      }
    </ul>
  );
}

export default MessageList;