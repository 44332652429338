import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import MessageError from "../../../component/MessageError";
import { ContextProvider } from "../../../context/BaseContext";
import { BreadCrumb } from "../../../component/breadcrumb";

export default function EditSection() {
  const history = useHistory();
  const { id, sectionId } = useParams();
  const location = useLocation();
  const context = useContext(ContextProvider);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [sectionName, setSectionName] = useState("");

  const onSubmit = async (data) => {
    let form = new FormData();
    form.append("class_id", id);
    form.append("title", data.nama);
    form.append("urutan", data.urutan);

    let response = await context.postRequest(
      `updateSection/${sectionId}`,
      form
    );
    if (response) {
      history.goBack();
      context.setNotification("success", "Section berhasil di tambahkan");
      reset();
    }
  };

  function setDefaultValue() {
    setSectionName(location.state?.title);
    setValue("nama", location.state?.title);
    setValue("urutan", location.state?.urutan);
  }

  useEffect(() => {
    setDefaultValue();
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Edit Section : {sectionName}</h5>
              </div>
              <BreadCrumb
                pageName={"Edit Section"}
                links={[
                  { url: "/kelas", name: "Kelas" },
                  { url: "", name: "Detail Kelas" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row">
              <label htmlFor="name" className="col-sm-2 col-form-label">
                Nama Section
              </label>
              <div className="col-sm-8">
                <input
                  {...register("nama", {
                    required: "Nama section harus diisi",
                  })}
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Masukkan nama section"
                />
                {errors.nama && <MessageError msg={errors.nama.message} />}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="urutan" className="col-sm-2 col-form-label">
                No Urutan
              </label>
              <div className="col-sm-3">
                <input
                  {...register("urutan", { required: "Urutan harus diisi" })}
                  type="number"
                  className="form-control"
                  id="urutan"
                  placeholder="Misal : 1"
                />
                {errors.urutan && <MessageError msg={errors.urutan.message} />}
              </div>
            </div>
            <div className="form-group row mt-5">
              <label className="col-sm-2 col-form-label" />
              <div className="col-sm-10">
                <button
                  type="button"
                  onClick={() => {
                    history.goBack();
                  }}
                  className="btn btn-outline-secondary"
                >
                  <i className="fas fa-arrow-left mr-1" />
                  Kembali
                </button>
                <button type="submit" className="btn btn-primary ml-2">
                  <i className="fas fa-save mr-1" />
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
