import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import Template from "../../component/Template";
import { useForm } from "react-hook-form";
import { ContextProvider } from "../../context/BaseContext";
import MessageError from "../../component/MessageError";
import Cropper from "react-easy-crop";
import {
  decryptString,
  encryptString,
  setStorage,
  getStorage,
  getCroppedImg,
  convertToBase64,
  dataURLtoFile,
} from "../../util/Functions";

export default function App() {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [fileToCrop, setFileToCrop] = useState("");
  const [nameFileCrop, setNameFileToCrop] = useState("");
  const [previewCover, setPreviewCover] = useState("");

  const inputFile = useRef(null);
  const context = useContext(ContextProvider);
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [imageError, setImageError] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    // setRefresh(true)
    console.log("DATA ", data);
    let form = new FormData();
    if (file != "") {
      form.append("avatar", file);
    }
    form.append("name", data.nama);
    form.append("title", data.title);
    form.append("description", data.deskripsi);
    form.append("phone", data.no_ponsel);
    form.append("website", data.website);
    form.append("linkedin", data.linkedin);
    form.append("facebook", data.facebook);
    form.append("youtube", data.youtube);
    form.append("instansi", data.instansi);

    let response = await context.postRequest("updateMentor", form);
    if (response) {
      console.log(response);
      if (response.status == true) {
        context.setNotification("success", response.message);
        await setStorage("profile_mentor", JSON.stringify(response.data));
        context.setValue("profile", response.data);
      } else {
        context.setNotification("danger", response.message);
      }
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    setRefresh(true);
    try {
      const croppedImage = await getCroppedImg(fileToCrop, croppedAreaPixels);
      // let file = dataURLtoFile(croppedImage, nameFileCrop);

      if (croppedImage) {
        let file = croppedImage.split(",");
        let newFile = dataURLtoFile(croppedImage, nameFileCrop);
        // console.log("XXX++++",croppedImage);
        console.log("=>>>>>", {
          name: nameFileCrop,
          data: file,
        });
        console.log("NEWDF", newFile);
        setFileToCrop("");
        setFile(newFile);
        setImage(croppedImage);
        setRefresh(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  async function handleChangeCover(file) {
    setRefresh(true);
    if (file.length != 0) {
      if (file[0].type == "image/jpeg" || file[0].type == "image/png") {
        convertToBase64(file[0])
          .then((result) => {
            setRefresh(false);
            setFileToCrop(result);
            setNameFileToCrop(file[0].name.replace(/\s/g, "_"));
            // setFileToCrop(result);
            // let avatar64 = result.split(",");
            // setPreviewCover(result);
            // setSaveCover({
            //   name: file[0].name.replace(/\s/g, "_"),
            //   data: avatar64[1],
            // });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setRefresh(false);
        context.setNotification(
          "danger",
          "Maaf, file yang anda upload bukan gambar"
        );
      }
    }
  }

  async function getProfile() {
    let mentor = await getStorage("profile_mentor");
    if (mentor) {
      var json = JSON.parse(mentor);
      // console.log(json)
      setImage(json.avatar);
      setImageError(false);
      setValue("email", json.email);
      setValue("nama", checkIfNull(json.name));
      setValue("title", checkIfNull(json.title));
      setValue("deskripsi", checkIfNull(json.description));
      setValue("no_ponsel", checkIfNull(json.phone));
      setValue("website", checkIfNull(json.website));
      setValue("linkedin", checkIfNull(json.linkedin));
      setValue("facebook", checkIfNull(json.facebook));
      setValue("youtube", checkIfNull(json.youtube));
      setValue("instansi", json.instansi);
    }
    // console.log("MENTOR", mentor);
  }
  function checkIfNull(d) {
    if (d == "null" || d == null) {
      return "";
    }
    return d;
  }
  useEffect(() => {
    getProfile();
  }, []);
  const password = watch("new_password");
  return (
    <Template>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Ubah Profile</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!">Ubah profile</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row mt-4">
            <div style={{ textAlign: "-webkit-center" }} className="col-md-4">
              <div
                className=""
                style={{
                  position: "relative",
                  height: "200px",
                  width: "200px",
                }}
              >
                {fileToCrop ? (
                  <Cropper
                    image={fileToCrop}
                    crop={crop}
                    // rotation={rotation}
                    zoom={zoom}
                    aspect={3 / 3}
                    onCropChange={setCrop}
                    cropShape="round"
                    // onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                ) : (
                  <img
                    onError={() => {
                      setImageError(true);
                    }}
                    src={
                      imageError
                        ? `${process.env.PUBLIC_URL}/assets/images/noImage.png`
                        : image
                    }
                    alt="userimage"
                    className="img-radius mb-4"
                    data-toggle="tooltip"
                    title="Joseph William"
                    style={{
                      display: "block",
                      width: "195px",
                      height: "195px",
                      objectFit: "cover",
                    }}
                  />
                )}
                <input
                  type="file"
                  onChange={(e) => {
                    handleChangeCover(e.target.files);
                  }}
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  accept="image/png, image/gif, image/jpeg"
                />
              </div>
              {fileToCrop ? (
                <button
                  onClick={() => showCroppedImage()}
                  type="button"
                  className="btn btn-primary mt-2"
                  style={{ display: "block", margin: "0 auto" }}
                  disabled={refresh}
                >
                  {refresh && (
                    <div
                      style={{ width: "20px", height: "20px" }}
                      className="spinner-border text-light mr-1"
                    />
                  )}
                  Crop Photo
                </button>
              ) : (
                <button
                  onClick={() => {
                    inputFile.current.click();
                  }}
                  type="button"
                  className="btn btn-primary mt-2"
                  style={{ display: "block", margin: "0 auto" }}
                  disabled={refresh}
                >
                  Ubah foto
                </button>
              )}
            </div>
            <div className="col-md-8">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Email
                  </label>
                  <div className="col-sm-6">
                    <input
                      readOnly={true}
                      type="text"
                      {...register("email", {
                        required: "Email harus diisi",
                      })}
                      className="form-control"
                      id="Password"
                      placeholder="Email"
                    />
                    {errors.email ? (
                      <MessageError msg={errors.email.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Nama Lengkap
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      {...register("nama", { required: "Nama harus diisi" })}
                      className="form-control"
                      id="Password"
                      placeholder="Nama Lengkap"
                    />
                    {errors.nama ? (
                      <MessageError msg={errors.nama.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Title
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      {...register("title", {
                        required: "Title harus diisi",
                      })}
                      className="form-control"
                      id="Password"
                      placeholder="Title"
                    />
                    {errors.title ? (
                      <MessageError msg={errors.title.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Instansi
                  </label>
                  <div className="col-sm-6">
                    <select
                      {...register("instansi", {
                        required: "Keterangan instansi harus diisi",
                      })}
                      className="form-control"
                    >
                      <option value="0">Bukan Instansi</option>
                      <option value="1">Ya Instansi</option>
                    </select>
                    {errors.title ? (
                      <MessageError msg={errors.title.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Deskripsi
                  </label>
                  <div className="col-sm-6">
                    <textarea
                      type="text"
                      {...register("deskripsi")}
                      className="form-control"
                      id="Password"
                      placeholder="Deskripsi"
                    />
                    {errors.deskripsi ? (
                      <MessageError msg={errors.deskripsi.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    No Ponsel
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      {...register("no_ponsel")}
                      className="form-control"
                      id="Password"
                      placeholder="No Ponsel"
                    />
                    {errors.no_ponsel ? (
                      <MessageError msg={errors.no_ponsel.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Website
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      {...register("website")}
                      className="form-control"
                      id="Password"
                      placeholder="Website"
                    />
                    {errors.website ? (
                      <MessageError msg={errors.website.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Linkedin
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      {...register("linkedin")}
                      className="form-control"
                      id="Password"
                      placeholder="Linkedin"
                    />
                    {errors.linkedin ? (
                      <MessageError msg={errors.linkedin.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Facebook
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      {...register("facebook")}
                      className="form-control"
                      id="Password"
                      placeholder="Facebook"
                    />
                    {errors.facebook ? (
                      <MessageError msg={errors.facebook.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Youtube
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      {...register("youtube")}
                      className="form-control"
                      id="Password"
                      placeholder="Youtube"
                    />
                    {errors.youtube ? (
                      <MessageError msg={errors.youtube.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  ></label>
                  <div className="col-sm-6">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ width: 150 }}
                    >
                      Ubah Profile
                    </button>
                    &nbsp;
                    {/* <button className="btn btn-outline-primary" style={{width:100}}>Batal</button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
