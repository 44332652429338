import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import ListFile from "./listFile";
import AddFile from './addFile'

export default function FilePendukung() {
  let { path, url } = useRouteMatch();

  useEffect(() => {
    console.log(">>", path, url);
  }, []);
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <ListFile path={url} />
      </Route>
      <Route exact path={`${path}/add`}>
        <AddFile />
      </Route>
    </Switch>
  );
}
