import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { ContextProvider } from "../../../../context/BaseContext";
import { BreadCrumb } from "../../../../component/breadcrumb";
import { useLocation } from "react-router-dom";
import DataTable from "../../../../component/DataTable";
import { useForm } from "react-hook-form";
import MessageError from "../../../../component/MessageError";
import swal from "sweetalert";

export default function ListFile({ path }) {
  const history = useHistory();
  const { contentId, id } = useParams();
  const location = useLocation();
  const context = useContext(ContextProvider);

  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(location.state?.content);
  const [fileDatas, setfileDatas] = useState([]);

  async function getFilesContent() {
    setLoading(true);
    let response = await context.getRequest(
      `content-file?content_id=${contentId}`
    );
    if (response) {
      setLoading(false);
      console.log(response["data"]["data"]);
      setfileDatas(response["data"]["data"]);
    }
  }

  function deleteFile(id) {
    swal({
      title: "Anda yakin?",
      text: "Jika anda menghapus file ini, file akan terhapus",
      icon: "warning",
      dangerMode: true,
      buttons: ["Tidak jadi", "Ya, saya akan hapus!"],
    }).then(async (willDelete) => {
      if (willDelete) {
        let form = new FormData();
        form.append("id", id);
        let response = await context.postRequest(`content-file-delete`, form);
        if (response) {
          swal("Hapus file berhasil dilakukan", {
            icon: "success",
          });
          getFilesContent();
        }
      }
    });
  }

  const RenderItem = ({ val }) => {
    const [showFormEdit, setshowFormEdit] = useState(false);
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      setValue,
    } = useForm();

    const onSubmit = async (data) => {
      console.log(data, val.id, val.content_id);
      let form = new FormData();
      form.append("id", val.id);
      form.append("class_id", id);
      form.append("content_id", val.content_id);
      form.append("nama_file", data.namaFile);

      let response = await context.postRequest("content-file-update", form);
      if (response) {
        context.setNotification("success", "Nama file berhasil diubah");
        reset();
        setshowFormEdit(false);
        getFilesContent();
      } else {
        context.setNotification("danger", "Nama file gagal diubah");
      }
    };

    return (
      <tr>
        <td>
          {showFormEdit ? (
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-sm-8">
                <input
                  {...register("namaFile", {
                    required: "nama file harus diisi",
                  })}
                  className="ml-2 form-control form-control-sm"
                  type="text"
                />
                {errors.namaFile && (
                  <MessageError msg={errors.namaFile.message} />
                )}
              </div>
              <div className="col-sm-4">
                <button
                  className="mr-1 btn btn-success btn-sm"
                  style={{ borderRadius: "50%" }}
                  type="submit"
                >
                  <i style={{ color: "white" }} className="fas fa-check" />
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  style={{ borderRadius: "50%" }}
                  type="button"
                  onClick={() => {
                    setshowFormEdit(false);
                    reset();
                  }}
                >
                  <i
                    style={{ color: "white", width: "14px" }}
                    className="fas fa-times"
                  />
                </button>
              </div>
            </form>
          ) : (
            <>{val.nama_file}</>
          )}
        </td>
        <td>
          <i className="fas fa-ellipsis-h" />
          {!showFormEdit && (
            <div className="overlay-edit">
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setshowFormEdit(true);
                  setValue("namaFile", val.nama_file);
                }}
                className="btn btn-icon btn-warning"
              >
                <i className="feather icon-edit-2" />
              </a>

              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  deleteFile(val.id);
                }}
                type="button"
                className="btn btn-icon btn-danger"
              >
                <i className="feather icon-trash-2" />
              </a>
            </div>
          )}
        </td>
      </tr>
    );
  };

  useEffect(() => {
    let isSubscribe = true;
    if (isSubscribe) {
      getFilesContent();
    }
    return () => {
      isSubscribe = false;
    };
    // console.log("content", content);
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">File Pendukung : {content.title}</h5>
              </div>
              <BreadCrumb
                pageName={"File Pendukung"}
                links={[
                  { url: "/kelas", name: "Kelas" },
                  { url: "", name: "Detail Kelas" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card user-profile-list">
        <div className="card-header">
          <button
            onClick={() => {
              history.push(`${path}/add`);
            }}
            className="btn btn-primary"
          >
            <i className="fa fa-plus mr-1" />
            Tambah File
          </button>
        </div>
        <div className="card-body">
          <div className="dt-responsive table-responsive">
            <DataTable
              className={"table table-striped table-bordered nowrap"}
              data={fileDatas}
              totalRows={fileDatas.length}
              show={[10, 20, 30, 40, 50]}
              onChangeSearch={(val) => {
                console.log(val);
              }}
              onChangeLimit={(val) => {
                console.log(val);
              }}
              onChangePage={(val) => {
                console.log(val);
              }}
              column={["Nama File", "Action"]}
              renderItem={(item, i) => <RenderItem val={item} key={i} />}
            />
          </div>
        </div>
      </div>
    </>
  );
}
