import React,{useContext,useState,useEffect} from 'react'
import {useRouteMatch,useHistory} from 'react-router-dom'
import { useForm } from "react-hook-form";
import MessageError from '../../component/MessageError'
import {ContextProvider} from '../../context/BaseContext'
import {setStorage,encryptString,decodeJWT} from '../../util/Functions'
export default function App(){
    const history=useHistory()
    const context=useContext(ContextProvider)
    const [refresh,setRefresh]=useState(false)
    const { register, handleSubmit, watch, formState: { errors },reset } = useForm();
    const onSubmit =async(data) => {
        setRefresh(true)
        // console.log("DATA ",data)
        let form = JSON.stringify({
            "email": data.username,
            "password": data.password,
            "remember_me": false
        })
        let response=await context.postRequest('login_mentor',form)
        if(response){
            console.log("RESPONSE : ",response.access_token)
            
            let auth=await encryptString(data.username+'|'+data.password)
            await setStorage('auth', auth)
            await setStorage('token', response.access_token)
            await setStorage('role','mentor')
            await setStorage("menuActive", "default")
            await getProfile()
            context.setValue('login',true)
            history.replace('/')
            reset()
        }
        setRefresh(false)
    }
    async function getProfile(){
        let response=await context.getRequest('profile_mentors2',false);
        let str=JSON.stringify(response.data.data);
        // str=encryptString(str)
        await setStorage('profile_mentor',str);
        console.log(JSON.parse(str))
    }
    useEffect(() => {
        async function a(){
            let a=await decodeJWT()
            console.log(a)
        }
        a()
        
    },[])
    let { url } = useRouteMatch();
    return(
        <>
            <h5 className="mb-4 f-w-400">Masuk sebagai mentor</h5>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-3">
                    <label className="floating-label" htmlFor="Email">Username</label>
                    <input autocomplete="off" type="text" {...register('username',{required:'Username harus diisi'})} className="form-control" id="Email" placeholder />
                    {errors.username?<MessageError msg={errors.username.message}/>:null}
                </div>
                <div className="form-group mb-4">
                    <label className="floating-label" htmlFor="Password">Password</label>
                    <input autocomplete="off" type="password" {...register('password',{required:'Password harus diisi'})} className="form-control" id="Password" placeholder />
                    {errors.password?<MessageError msg={errors.password.message}/>:null}
                </div>
                {/* <div className="custom-control custom-checkbox text-left mb-4 mt-2">
                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                    <label className="custom-control-label" htmlFor="customCheck1">Save credentials.</label>
                </div> */}
                <button type="submit" className="btn btn-block btn-primary mb-1" style={{fontSize:17}}>Masuk {refresh?<div class="spinner-grow text-light" role="status"></div>:null}</button>
            </form>
            <div className="text-center">
                {/* <div className="saprator my-4"><span>OR</span></div> */}
                {/* <button className="btn text-white bg-facebook mb-2 mr-2  wid-40 px-0 hei-40 rounded-circle"><i className="fab fa-facebook-f" /></button>
                <button className="btn text-white bg-googleplus mb-2 mr-2 wid-40 px-0 hei-40 rounded-circle"><i className="fab fa-google-plus-g" /></button>
                <button className="btn text-white bg-twitter mb-2  wid-40 px-0 hei-40 rounded-circle"><i className="fab fa-twitter" /></button> */}
                <p className="mb-2 mt-4 text-muted">Lupa password? <a href={`${url}/forgot-password`} className="f-w-400">Reset</a></p>
                <p className="mb-0 text-muted">Tidak punya akun? <a href={`${url}/signup`} className="f-w-400">Daftar</a></p>
            </div>
        </>
    )
}