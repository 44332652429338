import React, { useEffect, useState, useContext, useRef } from "react";
import { ContextProvider } from "../../../context/BaseContext";
import { convertToBase64, getStorage } from "../../../util/Functions";
import ChatItem from "./chat-item";
import { messaging } from "../../../config/firebase.config";
import { LoadingBall } from "../../../component/Loading";

const ChatRoom = ({ data, chatList, loadData, refresh, getChat }) => {
  const context = useContext(ContextProvider);
  const chatRef = useRef();
  const inputFile = useRef();
  const [loading, setLoading] = useState(false);
  const [textToSend, setTextToSend] = useState("");
  const [fileUpload, setfileUpload] = useState(null);
  const [previewImg, setpreviewImg] = useState("");

  function imgClick(img) {
    setpreviewImg(img);
  }

  useEffect(() => {
    bottomScroll();
  }, [previewImg]);

  function bottomScroll() {
    if (!fileUpload && !previewImg) {
      const bottomBtn = document.querySelector(".tobottom-btn");
      const container = document.getElementById("myDIV");

      container.addEventListener("scroll", (e) => {
        // console.log('/scrol', container.scrollHeight, container.scrollTop)
        if (
          container.scrollTop <
          container.scrollHeight - container.clientHeight - 200
        ) {
          bottomBtn.classList.add("show");
        } else {
          bottomBtn.classList.remove("show");
        }
      });

      bottomBtn.addEventListener("click", smoothScrollBackToTop);

      function smoothScrollBackToTop() {
        const targetPosition = container.scrollHeight;
        const startPosition = container.scrollTop;
        const distance = targetPosition - startPosition;
        const duration = 750;
        let start = null;

        window.requestAnimationFrame(step);

        function step(timestamp) {
          if (!start) {
            start = timestamp;
          }

          const progress = timestamp - start;
          container.scrollTo(
            0,
            easeInOutCubic(progress, startPosition, distance, duration)
          );
          if (progress < duration) {
            window.requestAnimationFrame(step);
          }
        }
      }

      function easeInOutCubic(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t * t + b;
        t -= 2;
        return (c / 2) * (t * t * t + 2) + b;
      }
    }
  }

  if (!previewImg) {
    return (
      <div
        className="card chat-card"
        style={{
          height: "100vh",
        }}
      >
        <>
          <div className="card-header d-flex justify-content-between align-items-center">
            <h5>{data.title}</h5>
          </div>
          <div className="card-body">
            <div
              id="myDIV"
              className="bg-light rounded p-3"
              style={{
                height: data.id ? "88%" : "100%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {refresh ? (
                // <div className="text-center m-5 text-secondary">
                //   <h6>Memuat Chat ...</h6>
                // </div>
                <LoadingBall />
              ) : null}
              <i
                className="fas fa-comments f-32"
                style={{
                  color: "#e9e9e9",
                  position: "absolute",
                  top: "48%",
                  left: "48%",
                }}
              />
              {chatList.length !== 0 ? (
                <div
                  ref={chatRef}
                  onLoad={() => {
                    chatRef.current.scrollIntoView({
                      block: "end",
                      inline: "nearest",
                    });
                  }}
                >
                  {chatList.map((chatItem) => (
                    <ChatItem
                      imgClick={imgClick}
                      key={chatItem.id}
                      chatItem={chatItem}
                    />
                  ))}
                  {loadData ? (
                    <div className="w-100 d-flex justify-content-center align-items-center mb-1">
                      <div
                        class="spinner-border spinner-border-sm text-center"
                        role="status"
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}

              <button type="text" className="tobottom-btn">
                <i class="fas fa-arrow-down"></i>
              </button>
            </div>
            {data.id ? (
              <div className="form-group m-t-15 d-flex align-items-center">
                <div>
                  <button
                    disabled={loading}
                    onClick={() => inputFile.current.click()}
                    type="button"
                    className="btn btn-icon polos mt-2 mr-1"
                    title="Upload gambar"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="far fa-image"
                    ></i>
                    <input
                      readOnly={loading}
                      onChange={(event) =>
                        handleUploadImage(event.target.files)
                      }
                      type="file"
                      id="file"
                      accept="image/png, image/jpeg"
                      ref={inputFile}
                      style={{ display: "none" }}
                    />
                  </button>
                </div>
                <textarea
                  readOnly={loading}
                  name="task-insert"
                  className="form-control bg-light rounded px-2"
                  style={{ resize: "none", marginRight: 5, marginLeft: 5 }}
                  placeholder="Kirim pesan"
                  value={textToSend}
                  onChange={(event) => setTextToSend(event.target.value)}
                />
                <div>
                  <button
                    disabled={loading}
                    onClick={() => sendChat("text")}
                    type="button"
                    className="btn btn-primary btn-icon"
                    title="Kirim"
                  >
                    {!loading ? (
                      <i className="fas fa-paper-plane mr-1" />
                    ) : (
                      <div
                        class="spinner-border spinner-border-sm text-light"
                        role="status"
                      ></div>
                    )}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </>
      </div>
    );
  } else {
    return <PreviewImage />;
  }

  function PreviewImage() {
    return (
      <div
        className="card chat-card"
        style={{
          height: "100vh",
        }}
      >
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Preview</h5>
          <button
            onClick={() => {
              setpreviewImg("");
            }}
            type="button"
            className="btn btn-transparent"
            title="Close"
          >
            <i class="fas fa-times" style={{ fontSize: 20 }}></i>
          </button>
        </div>
        <div className="card-body">
          <div
            id="myDIV"
            className="bg-light rounded p-3 d-flex justify-content-center align-items-center"
            style={{ height: fileUpload ? "85%" : "100%" }}
          >
            <img
              src={previewImg}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          </div>
          {fileUpload ? (
            <div className="form-group m-t-15 d-flex align-items-center justify-content-between">
              <div></div>
              <div className="row">
                <button
                  disabled={loading}
                  onClick={() => inputFile.current.click()}
                  type="button"
                  className="btn btn-transparent"
                  style={{
                    backgroundColor: "#e4e4e490",
                    height: 70,
                    minWidth: 50,
                  }}
                  title="Ubah gambar"
                >
                  <i
                    class="fas fa-sync-alt"
                    style={{ fontSize: 20, color: "#D71C20" }}
                  ></i>
                  <h6 style={{ fontSize: 12, color: "#D71C20" }}>
                    Ubah
                    <br />
                    Gambar
                  </h6>
                  <input
                    onChange={(event) => handleUploadImage(event.target.files)}
                    type="file"
                    id="file"
                    accept="image/png, image/jpeg"
                    ref={inputFile}
                    style={{ display: "none" }}
                  />
                </button>
              </div>
              <div>
                <button
                  disabled={loading}
                  onClick={() => sendChat("image")}
                  type="button"
                  className="btn btn-primary btn-icon "
                  title="Kirim"
                >
                  {!loading ? (
                    <i className="fas fa-paper-plane mr-1" />
                  ) : (
                    <div
                      class="spinner-border spinner-border-sm text-light"
                      role="status"
                    ></div>
                  )}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  async function sendChat(type) {
    if (textToSend || fileUpload) {
      setLoading(true);
      let profile_mentor = await getStorage("profile_mentor");
      let id = JSON.parse(profile_mentor).id;
      let form = new FormData();
      form.append("class_id", data.id);
      form.append("type", "mentor");
      form.append("user_id", id);

      if (type == "image") {
        form.append("content", fileUpload);
        form.append("content_type", "image");
      } else {
        form.append("content", textToSend);
        form.append("content_type", "text");
      }
      //console.log(form)
      let res = await context.postRequest("chat-mentor-push", form);
      getChat("loadData");
      setTextToSend("");
      setpreviewImg("");
      setfileUpload("");
      console.log("res", res);
      setLoading(false);
      window.scrollTo(0, document.body.scrollHeight);
    }
  }
  async function handleUploadImage(file) {
    if (file.length != 0) {
      if (file[0].type == "image/jpeg" || file[0].type == "image/png") {
        const regDot = /([!@#$%^&*()]|(?:[.](?![a-z0-9]+$)))/gim;
        let fn = file[0].name.replace(/\s+/g, "_");
        let filename = fn.replace(regDot, "_");

        convertToBase64(file[0])
          .then((result) => {
            let img64 = result.split(",");
            console.log(filename);
            setpreviewImg(result);
            setfileUpload(
              JSON.stringify({
                name: filename,
                data: img64[1],
              })
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        context.setNotification("danger", "File yang Anda kirim bukan gambar.");
      }
    }
  }
};

export default ChatRoom;
