import React, { useEffect, useState, useContext } from "react";
import Template from "../../component/Template";
import MessageList from "./message/message-list";
import ChatRoom from "./chat-room";
import { ContextProvider } from "../../context/BaseContext";
import firebase, { messaging } from "../../config/firebase.config";

export default function App() {
  const context = useContext(ContextProvider);
  const [data, setData] = useState({});
  const [listGrup, setListGrup] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loadData, setLoadData] = useState(false);

  async function getListChat() {
    let res = await context.getRequest("chats-mentor");
    if (res) {
      console.log(res.data.data);
      setListGrup(res.data.data);
    }
  }
  async function getChat(type) {
    if (data.id) {
      if (type == "refresh") {
        setRefresh(true);
      } else {
        setLoadData(true);
      }
      let res = await context.getRequest("chats-detail-mentor/" + data.id);
      if (res) {
        console.log(res.data.data);
        await context.getCountNotif();
        await setChatList(res.data.data.reverse());
        setLoadData(false);
        setRefresh(false);
      }
    }
  }

  useEffect(() => {
    getChat("refresh");
  }, [data]);

  if (firebase.messaging.isSupported()) {
    messaging.onMessage((payload) => {
      console.log("MASUK NOTIF");
      context.getCountNotif();
      getListChat();
      getChat("loadData");
    });
  }
  useEffect(() => {
    getListChat();
  }, []);

  async function setId(data) {
    await setData(data);
  }

  return (
    <Template>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Chat</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!">Chat</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h5>Chat</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-xl-4">
              <div
                className="card user-Messages-card"
                style={{ height: "100vh" }}
              >
                <div className="card-body p-0">
                  <div
                    className="tab-content p-0"
                    id="pills-tabContent"
                    style={{ height: "100%" }}
                  >
                    <div
                      className="tab-pane fade active show"
                      id="pills-pesan"
                      role="tabpanel"
                      aria-labelledby="pills-pesan-tab"
                      style={{ height: "100%" }}
                    >
                      <MessageList setId={setId} data={listGrup} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <ChatRoom
                refresh={refresh}
                loadData={loadData}
                data={data}
                chatList={chatList}
                getChat={getChat}
              />
              {/* <NewChat
                                        contactToChat={chatToDisplay}
                                        setIsChatExist={setIsChatExist}
                                        getMessageList={getMessageList}
                                        setChatToDisplay={setChatToDisplay}
                                    /> */}
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
