import React, { useState, useEffect, useContext } from "react";
import Template from "../../component/Template";
import DataTable from "../../component/DataTable";
import { ContextProvider } from "../../context/BaseContext";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import swal from "sweetalert";

import AddKuis from "./AddKuis";
import EditKuis from "./EditKuis";
import AddSoal from "./Soal/AddSoal";
import EditSoal from "./Soal/EditSoal";
import Soal from "./Soal/index";

export default function App() {
  let { path, url } = useRouteMatch();
  const history = useHistory();

  const context = useContext(ContextProvider);

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState({});
  const [totalRows, setTotalRows] = useState(0);

  async function getData() {
    setData({});
    var page = offset / limit + 1;
    let response = await context.getRequest(
      `getQuizByMentor?page=${page}&limit=${limit}&search=${search}`
    );
    if (response) {
      // console.log(response);
      if ("data" in response.data.data) {
        setData(response.data.data.data);
        setTotalRows(response.data.data.total);
      } else {
        setData([]);
        setTotalRows(0);
      }
    }
  }

  useEffect(() => {
    getData();
  }, [limit, offset, search]);
  return (
    <Template>
      <Switch>
        <Route exact path={`${path}`}>
          <RenderIndex />
        </Route>
        <Route path={`${path}/add`}>
          <AddKuis path={path} />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <EditKuis path={path} />
        </Route>

        <Route path={`${path}/soal/:idQuiz`}>
          <Soal path={path} />
        </Route>
        <Route path={`${path}/add-soal/:idQuiz`}>
          <AddSoal path={path} />
        </Route>
        <Route path={`${path}/edit-soal/:idSoal/:idQuiz`}>
          <EditSoal path={path} />
        </Route>
      </Switch>
    </Template>
  );
  function RenderIndex() {
    return (
      <>
        <div className="page-header">
          <div className="page-block">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-header-title">
                  <h5 className="m-b-10">Kuis</h5>
                </div>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">
                      <i className="feather icon-home" />
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#!">Kuis</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <button
              onClick={() => {
                history.push({
                  pathname: `${path}/add`,
                  getData: getData.bind(this),
                });
              }}
              className="btn btn-primary"
            >
              {" "}
              <i className="fa fa-plus mr-1" />
              Tambah Kuis
            </button>
          </div>
          <div className="card-body">
            <div className="dt-responsive table-responsive">
              <DataTable
                className={"table table-striped table-bordered nowrap"}
                data={data}
                totalRows={totalRows}
                show={[10, 20, 30, 40, 50]}
                onChangeSearch={(val) => {
                  setSearch(val);
                }}
                onChangeLimit={(val) => {
                  setLimit(val);
                }}
                onChangePage={(val) => {
                  setOffset(val);
                }}
                column={["Kuis", "Waktu", "Soal", ""]}
                renderItem={(item, i) => <RenderItem item={item} key={i} />}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
  function RenderItem({ item, i }) {
    return (
      <tr>
        <td>
          {item.title}
          <br />
          {item.description}
        </td>
        <td>{item.time}</td>
        <td>{item.questions.length}</td>
        <td style={{ width: 100 }}>
          <button
            type="button"
            onClick={() => {
              history.push({
                pathname: `${path}/soal/${item.id}`,
                getData: getData.bind(this),
              });
            }}
            className="btn btn-outline-info"
          >
            <i className="feather icon-list"></i>&nbsp;Pertanyaan
          </button>
          &nbsp;
          <button
            type="button"
            onClick={() => {
              history.push({
                pathname: `${path}/edit/${item.id}`,
                getData: getData.bind(this),
              });
            }}
            className="btn btn-outline-warning"
          >
            <i className="feather icon-edit-2"></i>&nbsp;Edit
          </button>
          &nbsp;
          {item.content_id == 0 && (
            <button
              type="button"
              onClick={() => {
                deleteQuiz(item.id);
              }}
              className="btn btn-outline-danger"
            >
              <i className="feather icon-trash-2"></i>&nbsp;Hapus
            </button>
          )}
        </td>
      </tr>
    );
  }
  async function deleteQuiz(id) {
    swal({
      title: "Anda yakin?",
      text: "Jika anda menghapus kuis ini, kuis dan semua pertanyaan akan terhapus",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      buttons: ["Tidak jadi", "Ya, saya akan hapus!"],
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await context.postRequest(`deleteQuiz/${id}`);
        if (response) {
          swal("Hapus kuis berhasil dilakukan", {
            icon: "success",
          });
          getData();
        }
      } else {
        swal("Tidak jadi hapus kuis ini");
      }
    });
  }
}
