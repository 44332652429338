import React, { useContext, useState, useEffect } from "react";
import Template from "../../component/Template";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MessageError from "../../component/MessageError";
import { ContextProvider } from "../../context/BaseContext";
import { currencyFormat, setStorage } from "../../util/Functions";
import moment from "moment";
import "moment/locale/id";

export default function App() {
  const his = useHistory();
  const context = useContext(ContextProvider);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const [history, setHistory] = useState([]);
  const [data, setData] = useState({
    no_rekening: "",
    nama_bank: "",
    rekening_atas_nama: "",
    points: 0,
  });
  const onSubmit = async (data) => {
    // setRefresh(true)
    console.log("DATA ", data);
    let form = new FormData();
    form.append("jumlah", data.jumlah);
    // let response = await context.postRequest("pengajuan", form);
    // if (response) {
    //   if (response.status) {
    //     context.setNotification("success", response.message);
    //   } else {
    //     context.setNotification("danger", response.message);
    //   }
    //   getHistory();
    //   // console.log(response)

    //   // history.replace(`${path}/add-soal/${response.data.id}`)
    // }
  };
  async function getData() {
    let response = await localStorage.getItem("profile_mentor");
    if (response) {
      let json = JSON.parse(response);
      setData({
        no_rekening: json.no_rekening,
        nama_bank: json.nama_bank,
        rekening_atas_nama: json.rekening_atas_nama,
        points: parseFloat(json.points),
      });
      console.log(json);
    }
  }
  async function getHistory() {
    let response = await context.getRequest("HistoryPengajuan");
    if (response) {
      console.log(response.data);
      setHistory(response.data.data);
    }
  }
  async function getProfile() {
    let response = await context.getRequest("profile_mentors2", false);
    console.log(response.data.data);
    if (response) {
      var json = response.data.data;
      var str = JSON.stringify(json);
      setData({
        no_rekening: json.no_rekening,
        nama_bank: json.nama_bank,
        rekening_atas_nama: json.rekening_atas_nama,
        points: parseFloat(json.points),
      });
      await setStorage("profile_mentor", str);
    }
    // let str=JSON.stringify(response.data.data);
    // str=encryptString(str)
    // await setStorage('profile_mentor',str);
    // console.log(JSON.parse(str))
  }
  useEffect(() => {
    getProfile();
    // getData();
    getHistory();
  }, []);
  return (
    <Template>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Withdraw</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!">Withdraw</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <div className="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h4 class="text-c-green">{data.no_rekening}</h4>
                      <h6 class="text-muted m-b-0">
                        {data.rekening_atas_nama}
                      </h6>
                      <h6 class="text-muted m-b-0">{data.nama_bank}</h6>
                    </div>
                    <div class="col-4 text-right">
                      <i class="fas fa-money-check f-28"></i>
                    </div>
                  </div>
                </div>
                <div class="card-footer bg-c-green">
                  <div class="row align-items-center">
                    <div class="col-9">
                      <p class="text-white m-b-0">Nomor Rekening</p>
                    </div>
                    <div class="col-3 text-right">
                      <a
                        href="#!"
                        onClick={(e) => {
                          e.preventDefault();
                          his.push("withdraw/rekening");
                        }}
                      >
                        <i class="feather icon-edit-2 text-white f-16"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h4 class="text-c-red">
                        Rp {currencyFormat(data.points)}
                      </h4>
                      <h6 class="text-muted m-b-0">Saldo yang dapat diambil</h6>
                    </div>
                    <div class="col-4 text-right">
                      <i class="fas fa-money-bill-wave f-28"></i>
                    </div>
                  </div>
                </div>
                <div class="card-footer bg-c-red">
                  <div class="row align-items-center">
                    <div class="col-9">
                      <p class="text-white m-b-0">Saldo</p>
                    </div>
                    <div class="col-3 text-right">
                      {/* <i class="feather icon-trending-up text-white f-16"></i> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-9">
          <div className="card">
            <div className="card-header">
              <h5>Withdraw</h5>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Nominal
                  </label>
                  <div className="col-sm-6">
                    <input
                      {...register("jumlah", {
                        min: {
                          value: 1000000,
                          message: "Minimal ajukan withdraw Rp.1.000.000",
                        },
                        max: {
                          value: 5000000,
                          message: "Minimal ajukan withdraw Rp.5.000.000",
                        },
                        required: "Nominal harus diisi",
                      })}
                      type="number"
                      className="form-control"
                      id="inputEmail3"
                      placeholder="Nominal"
                    />
                    {errors.jumlah && errors.jumlah.type === "required" ? (
                      <MessageError msg={errors.jumlah.message} />
                    ): null}
                    {errors.jumlah && errors.jumlah.type === "min" ? (
                      <MessageError msg={errors.jumlah.message} />
                    ) : null}
                    {errors.jumlah && errors.jumlah.type === "max" ? (
                      <MessageError msg={errors.jumlah.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  ></label>
                  <div className="col-sm-4">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ width: 100 }}
                    >
                      Ajukan
                    </button>
                    &nbsp;
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5>Riwayat</h5>
            </div>
            <div className="card-body">
              <div className="dt-responsive table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th style={{ width: 200 }}>Tanggal</th>
                      <th style={{ width: 150 }}>Kode</th>
                      <th>Jumlah</th>
                      <th colSpan={2} style={{ textAlign: "center" }}>
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((val, i) => {
                      var status = "";
                      if (val.status == 0) {
                        status = "Pengajuan";
                      } else if (val.status == 1) {
                        status = "Disetujui";
                      } else if (val.status == 2) {
                        status = "Ditolak";
                      }
                      return (
                        <tr key={i}>
                          <th>{++i}</th>
                          <th>
                            {moment(
                              val.created_at,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("llll")}
                          </th>
                          <th>{val.kode_withdraw}</th>
                          <th>Rp {currencyFormat(val.jumlah)}</th>
                          <th
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            {status}
                          </th>
                          <th
                            style={{
                              backgroundColor: "whitesmoke",
                              textAlign: "center",
                            }}
                          >
                            {val.status == 1 ? (
                              <a href={val.bukti_transfer} target="_blank">
                                Bukti Transfer
                              </a>
                            ) : null}
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
