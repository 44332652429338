import React, { useState, useEffect, useContext, useRef } from "react";
import Template from "../../component/Template";
import { ContextProvider } from "../../context/BaseContext";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import moment from "moment";

export default function ScheduleLiveKelas() {
  const context = useContext(ContextProvider);
  const reffTable = useRef();

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [listReport, setListReport] = useState([]);

  async function getDataLaporan() {
    let response = await context.getRequest(
      `reportTransactionMentor?date_start=${startDate}&date_end=${endDate}&page=1`
    );
    // console.log("RESPONSE : ", response);
    // console.log(moment().format("YYYY-MM-DD"));
    if (response) {
      // console.log(response.data.data);
      setListReport(response.data.data);
    }
  }

  useEffect(() => {
    getDataLaporan();
  }, [startDate, endDate]);

  return (
    <Template>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Laporan Penjualan Kelas</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!">Laporan Penjualan Kelas</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <div>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="btn btn-success mr-2"
              table="laporan-penjualan"
              filename={`Penjualan_${moment(startDate).format(
                "DD-MMM-YYYY"
              )}_${moment(endDate).format("DD-MMM-YYYY")}`}
              sheet="tablexls"
              buttonText="Download as XLS"
            />
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-primary">Print this out!</button>
              )}
              content={() => reffTable.current}
            />
          </div>
        </div>
        <div className="card-body">
          <form>
            <div className="form-group row">
              <label htmlFor="date_start" className="col-sm-1 col-form-label">
                Tanggal
              </label>
              <div className="col-sm-4 mb-2">
                <input
                  onChange={(e) => setStartDate(e.target.value)}
                  defaultValue={startDate}
                  type="date"
                  className="form-control"
                  id="date_start"
                  placeholder="Masukkan tanggal mulai kelas"
                />
              </div>
              <div className="col-sm-4 mb-2">
                <input
                  onChange={(e) => setEndDate(e.target.value)}
                  defaultValue={endDate}
                  type="date"
                  className="form-control"
                  id="date_end"
                  placeholder="Masukkan tanggal akhir kelas"
                />
              </div>
            </div>
          </form>
          <div className="dt-responsive table-responsive">
            <table
              ref={reffTable}
              id="laporan-penjualan"
              className="table table-striped table-bordered nowrap"
            >
              <thead>
                <tr>
                  <th>Waktu Transaksi</th>
                  <th>No Order</th>
                  <th>Kelas</th>
                  <th>Harga Kelas</th>
                  <th>Harga Bayar</th>
                  <th>Metode Pembayaran</th>
                </tr>
              </thead>
              <tbody>
                {listReport.length == 0 ? (
                  <tr>
                    <td className="text-center" colSpan="6">
                      Data tidak ditemukan pada range tanggal tersebut
                    </td>
                  </tr>
                ) : (
                  <>
                    {listReport.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            {moment(item.transaction_time).format(
                              "DD MMM YYYY - HH:mm:ss"
                            )}
                          </td>
                          <td>{item.order_id}</td>
                          <td>{item.title}</td>
                          <td>
                            Rp.{item.real_price == null ? 0 : item.real_price}
                          </td>
                          <td>
                            Rp.
                            {item.gross_amount == null ? 0 : item.gross_amount}
                          </td>
                          <td>{item.payment_type}</td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Template>
  );
}
