import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import MessageError from "../../../component/MessageError";
import { ContextProvider } from "../../../context/BaseContext";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import swal from "sweetalert";

export default function App({ path }) {
  const location = useLocation();
  const { idQuiz } = useParams();
  const context = useContext(ContextProvider);
  const history = useHistory();
  const [jawaban, setJawaban] = useState([]);
  const [question, setQuestion] = useState("");
  const [keys, setKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [cannotBack, setCannotBack] = useState(false);
  const [titleKuis, setTitleKuis] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    // setRefresh(true)
    // console.log("DATA ", data);
    var corr = jawaban.filter((val) => val.correct == 1);
    // alert(corr.length)
    if (question != "" && jawaban.length != 0 && corr.length != 0) {
      setLoading(true);
      let form = new FormData();
      form.append("question", question);
      form.append("num", data.num);
      form.append("answers", JSON.stringify(jawaban));
      form.append("quiz_id", idQuiz);

      let response = await context.postRequest(`add-question`, form);
      if (response) {
        // console.log(response)
        setLoading(false);
        context.setNotification("success", response.message);
        getData();
        setQuestion("");
        setJawaban([]);
        reset();
      }
    } else {
      if (question == "") {
        swal("Pertanyaan tidak boleh kosong", {
          icon: "warning",
        });
      } else if (jawaban.length == 0) {
        swal("Jawaban tidak boleh kosong", {
          icon: "warning",
        });
      } else if (corr.length == 0) {
        swal("Jawaban harus ada yang bernilai benar", {
          icon: "warning",
        });
      }
    }
    // let form=new FormData();
    // form.append('title',data.title)
    // form.append('time',data.time)
    // form.append('description',data.description)
    // form.append('content_id',data.content_id==''?0:data.content_id)
    // // form.append('questions[]',"")
    // let response=await context.postRequest('addQuiz',form)
    // if(response){
    //     console.log(response)
    //     history.replace(`${path}/add-soal/${response.data.id}`)
    // }
  };
  function randomKey() {
    var i = "";
    for (var a = 0; a < 5; a++) {
      i = i.concat(Math.ceil(Math.random() * 10));
    }
    return i;
  }
  async function getData() {
    let response = await context.getRequest(`getQuizById/${idQuiz}`);
    if (response) {
      console.log(response.data.data.data[0]);
      // console.log(response.data.data.data[0]);
      // setData(dt.questions);
      setTitleKuis(response.data.data.data[0].title);
      if (response.data.data.data[0].questions.length == 0) {
        setCannotBack(true);
      } else {
        setCannotBack(false);
      }
    }
  }
  useEffect(() => {
    setKey(randomKey());
    // alert(randomKey())
    getData();
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Tambah Pertanyaan Kuis: {titleKuis}</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/kuis">Kuis</a>
                </li>
                <li className="breadcrumb-item">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                    href="#!"
                  >
                    Pertanyaan
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a onClick={(e) => e.preventDefault()} href="#!">
                    Tambah Pertanyaan
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Nomor Urut
              </label>
              <div className="col-sm-4">
                <input
                  type="number"
                  {...register("num", { required: "Nomor harus diisi" })}
                  className="form-control"
                  id="inputEmail3"
                  placeholder="No Urut"
                />
                {errors.num ? <MessageError msg={errors.num.message} /> : null}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                Pertanyaan
              </label>
              <div className="col-sm-8">
                {/* <textarea {...register('pertanyaan',{required:'Deskripsi harus diisi'})} type="text" className="form-control" id="inputEmail3" placeholder="Deskripsi" />
                                    {errors.pertanyaan?<MessageError msg={errors.pertanyaan.message}/>:null} */}
                <CKEditor
                  editor={ClassicEditor}
                  data={question}
                  style={{ height: 400 }}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setQuestion(data);
                    // console.log( { event, editor, data } );
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>
            <hr />
            <div className="row" style={{ width: "100%" }}>
              {/* <h5 style={{marginLeft:20}}>Jawaban</h5>&nbsp;&nbsp;&nbsp; */}
              <a
                href="#!"
                className="btn btn-outline-info"
                onClick={(e) => {
                  e.preventDefault();
                  var j = jawaban;
                  j.push({
                    id: randomKey(),
                    correct: 0,
                    text: "",
                  });
                  setJawaban(j);
                  setKey(randomKey());
                }}
                style={{ marginLeft: 20 }}
              >
                Tambah Jawaban
              </a>
            </div>
            <br />
            <br />
            {jawaban.map((val, i) => {
              return (
                <div key={keys + "_" + val.id} className="form-group row">
                  <label className="col-sm-1 col-form-label"></label>
                  <div className="col-sm-1">
                    <input
                      type="radio"
                      name={`jawaban[]`}
                      onChange={(e) => {
                        let j = jawaban;
                        let ind = j.findIndex((vv) => vv.id == val.id);
                        for (var aa = 0; aa < j.length; aa++) {
                          j[aa]["correct"] = 0;
                        }
                        j[ind]["correct"] = 1;
                        setJawaban(j);
                        // console.log(val.id,j)
                      }}
                      value={val.id}
                    />
                  </div>
                  <div className="col-sm-8">
                    <CKEditor
                      editor={ClassicEditor}
                      data={val.text}
                      style={{ height: 400 }}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        let j = jawaban;
                        let ind = j.findIndex((vv) => vv.id == val.id);
                        j[ind]["text"] = data;
                        setJawaban(j);
                        console.log(j);
                      }}
                      onBlur={(event, editor) => {
                        // console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        // console.log( 'Focus.', editor );
                      }}
                    />
                  </div>
                  <div className="col-sm-1">
                    <button
                      type="button"
                      onClick={() => {
                        // deleteSoal(val.id)
                        let j = jawaban;
                        let ind = j.findIndex((vv) => vv.id == val.id);
                        j.splice(ind, 1);
                        setJawaban(j);
                        // console.log(j)
                        setKey(randomKey());
                      }}
                      class="btn btn-outline-danger"
                    >
                      <i class="feather icon-trash-2"></i>&nbsp;Hapus
                    </button>
                  </div>
                </div>
              );
            })}

            <div className="form-group row mt-5">
              <label className="col-sm-2 col-form-label" />
              <div className="col-sm-10">
                {!cannotBack && (
                  <button
                    type="button"
                    onClick={() => {
                      history.goBack();
                      location.getData();
                    }}
                    className="btn btn-outline-secondary"
                  >
                    <i className="fas fa-arrow-left mr-1" />
                    Kembali
                  </button>
                )}
                <button type="submit" className="btn btn-primary ml-2">
                  {loading ? (
                    <div
                      style={{ width: "20px", height: "20px" }}
                      className="spinner-border text-light mr-1"
                    />
                  ) : (
                    <i className="fas fa-save mr-1" />
                  )}
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
