import React, { useEffect, useState, useRef, useContext } from 'react';
import { ContextProvider } from '../../../context/BaseContext';
import moment from 'moment';
import { getStorage } from '../../../util/Functions';

const ChatItem = ({ chatItem, imgClick }) => {
  const context = useContext(ContextProvider);
  const defaultAvatar = "assets/images/empty_user.png";
  const [isMessageFromMe, setIsMessageFromMe] = useState(false);
  const [timestamp, setTimestamp] = useState("");
  const [errorAvatar, setErrorAvatar] = useState(false);
  const chatRef = useRef();

  async function checkIsMessageFromUser() {
    let profile_mentor = await getStorage('profile_mentor')
    let id = JSON.parse(profile_mentor).id
    if (chatItem.user_id === id) {
      setIsMessageFromMe(true);
    } else {
      setIsMessageFromMe(false);
    }
  }

  function checkIsMessageToday() {
    const todayDate = moment(new Date()).format("DD-MM-YYYY");
    const chatDate = moment(chatItem.created_at).format("DD-MM-YYYY");
    if (chatDate === todayDate) {
      setTimestamp(moment(chatItem.created_at).format("HH:mm"));
    } else {
      setTimestamp(moment(chatItem.created_at).format("DD/MM/YY, HH:mm"));
    }
  }

  useEffect(() => {
    checkIsMessageFromUser();
    checkIsMessageToday();
  }, [chatItem]);

  return (
    <>
      {isMessageFromMe ?
        <div className="row m-b-20 send-chat">
          <div className="col">
            <div className="msg pt-1" style={{maxWidth:'90%'}}>
              <div className="d-flex justify-content-between align-items-center">
                {/* <Options /> */}
                <p className="text-right mb-0 f-10" >Anda</p>
              </div>
              {chatItem.content_type == 'text' ?
                <p className="m-b-0 f-14">{chatItem.content}</p>
                :
                <button onClick={() => imgClick(chatItem.img)} className='btn btn-transparent p-0'>
                  <img
                    src={chatItem.img}
                    style={{ width: 150, height: 100, objectFit: 'cover' }}
                  />
                </button>}
            </div>
            <p className="text-muted m-b-0">
              <i className="fa fa-clock-o m-r-10" />
              {timestamp}
            </p>
          </div>
          <div className="col-auto p-l-0">
            <img
              style={{ width: "40px", height: "40px", objectFit: "cover" }}
              src={
                errorAvatar
                  ? defaultAvatar
                  : chatItem.users.avatar
              }
              alt="user-avatar"
              className="img-radius"
              onError={() => setErrorAvatar(true)}
            />
          </div>
        </div>
        :
        <div className="row m-b-20 received-chat">
          <div className="col-auto p-r-0 d-flex flex-column justify-content-center align-items-center">
          <img
              style={{ width: "40px", height: "40px", objectFit: "cover" }}
              src={
                errorAvatar
                  ? defaultAvatar
                  : chatItem.users.avatar
              }
              alt="user-avatar"
              className="img-radius"
              onError={() => setErrorAvatar(true)}
            />
          </div>
          <div className="col">
            <div className="msg" style={{maxWidth:'90%'}} >
            <p className="mb-0 text-muted" style={{ fontSize: "10px" }}>{chatItem.users.name}</p>
              {chatItem.content_type == 'text' ?
                <p className="m-b-0 f-14">{chatItem.content}</p>
                :
                <button onClick={() => imgClick(chatItem.img)} className='btn btn-transparent p-0'>
                  <img
                    src={chatItem.img}
                    style={{ width: 150, height: 100, objectFit: 'cover' }}
                  />
                </button>}
            </div>
            <p className="text-muted m-b-0">
              <i className="fa fa-clock-o m-r-10" />
              {timestamp}
            </p>
          </div>
        </div>
      }
    </>
  );
}

export default ChatItem;