import React from "react";

export default function IframeZoom({ token }) {
  return (
    <iframe
      allowusermedia
      allow="camera *;microphone *"
      src={`https://zoom.wakool.academy:9999/meeting.html?${token}`}
      title="Zoom Academy"
      style={{ width: "100%", height: "100%" }}
    />
  );
}
