import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ContextProvider } from "../../context/BaseContext";
import MessageError from "../../component/MessageError";
import {
  convertToBase64,
  getCroppedImg,
  dataURLtoFile,
} from "../../util/Functions";
import { BreadCrumb } from "../../component/breadcrumb";
import Cropper from "react-easy-crop";


export default function EditKelas() {
  const history = useHistory();
  let { id } = useParams();
  const context = useContext(ContextProvider);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isInstansi, setIsInstansi] = useState(false);
  const [listInstruktur, setListInstruktur] = useState([]);

  const [validateCategory, setValidateCategory] = useState("");
  const [allDataCategory, setAllDataCategory] = useState([]);
  const [isValidDesc, setIsValidDesc] = useState("");
  const [isValidCover, setIsValidCover] = useState("");

  const [previewCover, setPreviewCover] = useState("");
  const [saveCover, setSaveCover] = useState("");
  const [saveCategory, setSaveCategory] = useState([]);
  const [defaultCategory, setDefaultCatergory] = useState([]);
  const [saveDesc, setSaveDesc] = useState("");
  const [saveSyarat, setSaveSyarat] = useState("");
  const [saveInstruktur, setSaveInstruktur] = useState([]);
  const [defaultInstruktur, setDefaultInstruktur] = useState([]);
  const [fileToCrop, setFileToCrop] = useState("");
  const [nameFileCrop, setNameFileToCrop] = useState("");

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onSubmit = async (data) => {
    if (saveCategory.length != 0 && saveDesc) {
      // console.log("COVER", saveCover);
      // console.log("CATEGORY", saveCategory);
      // console.log("DATA ", data);
      setLoading(true)
      let form = new FormData();
      form.append("title", data.judul);
      form.append("description", saveDesc);
      form.append("level", data.level);
      form.append("prerequisite", saveSyarat ? saveSyarat : "-");
      form.append("prices", data.harga);
      // form.append("category", JSON.stringify(saveCategory));
      if (saveCover) {
        form.append("img", JSON.stringify(saveCover));
      }
      if (isInstansi) {
        saveInstruktur.forEach((instruktur) =>
          form.append("instruktur[]", instruktur)
        );
      }
      let response = await context.postRequest(`MentorUpdateKelas/${id}`, form);
      if (response) {
        setLoading(false)
        history.goBack();
        context.setNotification("success", "Kelas berhasil diubah");
      } else {
        setLoading(false)
        context.setNotification(
          "danger",
          "Terjadi kesalahan, coba beberapa saat lagi"
        );
      }
    } else {
      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
      if (saveCategory.length == 0) {
        setValidateCategory("Pilih minimal 1 kategori");
      } else {
        setValidateCategory("");
      }
      if (!saveDesc) {
        setIsValidDesc("Deskripsi harus diisi");
      } else {
        setIsValidDesc("");
      }
    }
  };

  async function getAllCategory() {
    let response = await context.getRequest(`getAllCategory`);
    // console.log(response);
    if (response) {
      let data = [];
      response.data.data.map((item) => {
        data.push({ value: item.title, label: item.title });
        if (item.children.length > 0) {
          item.children.map((itemChild) => {
            data.push({ value: itemChild.title, label: itemChild.title });
          });
        }
      });
      setAllDataCategory(data);
    }
  }

  async function getDataKelas() {
    let response = await context.getRequest(`getKelasByID/${id}`);
    if (response) {
      // console.log(response.data);
      setValue("judul", response.data.data.title);
      setSaveDesc(response.data.data.description);
      setValue("level", response.data.data.level);
      setValue("harga", response.data.data.prices);
      setSaveSyarat(
        response.data.data.prerequisite == "-"
          ? ""
          : response.data.data.prerequisite
      );
      setPreviewCover(response.data.data.img);

      //=====SET VALUE FOR FORM
      //Category
      let xx = [];
      let yy = [];
      response.data.category_classes.forEach((item) =>
        xx.push({ value: item.category.title, label: item.category.title })
      );
      setDefaultCatergory(xx);
      //Instruktur
      // console.log("xxx", response.data.data.instruktur);
      response.data.data.instruktur.forEach((item) =>
        yy.push({ value: item.instruktur.id, label: item.instruktur.nama })
      );
      setDefaultInstruktur(yy);
      //SET VALUE STATE
      let chooseCategory = [];
      xx.forEach((item) => chooseCategory.push(item.value));
      setSaveCategory(chooseCategory);
    }
  }

  async function handleChangeCover(file) {
    setRefresh(true);
    if (file.length != 0) {
      if (file[0].type == "image/jpeg" || file[0].type == "image/png") {
        convertToBase64(file[0])
          .then((result) => {
            setRefresh(false);
            setFileToCrop(result);
            setNameFileToCrop(file[0].name.replace(/\s/g, "_"));
            // let avatar64 = result.split(",");
            // setPreviewCover(result);
            // setSaveCover({
            //   name: file[0].name.replace(/\s/g, "_"),
            //   data: avatar64[1],
            // });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        alert("file yang di upload bukan gambar");
      }
    }
  }

  const showCroppedImage = useCallback(async () => {
    setRefresh(true);
    try {
      const croppedImage = await getCroppedImg(fileToCrop, croppedAreaPixels);
      // let file = dataURLtoFile(croppedImage, nameFileCrop);

      if (croppedImage) {
        let file = croppedImage.split(",");
        // console.log("XXX++++",croppedImage);
        // console.log("=>>>>>", {
        //   name: nameFileCrop,
        //   data: file,
        // });
        setFileToCrop("");
        setSaveCover({ name: nameFileCrop, data: file[1] });
        setPreviewCover(croppedImage);
        // convertToBase64(file).then((result) => setPreviewCover(result));
        setRefresh(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  async function hendleGetProfileMentor() {
    let data = await JSON.parse(localStorage.getItem("profile_mentor"));
    let response = await context.getRequest(`instruktur`);

    if (data && response) {
      let xx = [];
      response.data.data.forEach((item) =>
        xx.push({ value: item.id, label: item.nama })
      );
      setListInstruktur(xx);
      setIsInstansi(data.instansi == "1" ? true : false);
    }
  }

  useEffect(() => {
    hendleGetProfileMentor();
    getAllCategory();
    getDataKelas();
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Edit Kelas</h5>
              </div>
              <BreadCrumb
                pageName={"Edit Kelas"}
                links={[{ url: "/kelas", name: "Kelas" }]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mt-2">
              <div className="col-lg-4">
                <div style={{ position: "relative", height: "200px" }}>
                  {fileToCrop ? (
                    <Cropper
                      image={fileToCrop}
                      crop={crop}
                      // rotation={rotation}
                      zoom={zoom}
                      aspect={3 / 2}
                      onCropChange={setCrop}
                      // onRotationChange={setRotation}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  ) : (
                    <img
                      src={
                        previewCover
                          ? previewCover
                          : process.env.PUBLIC_URL +
                            `/assets/images/noImage.png`
                      }
                      style={{
                        width: "100%",
                        height: "210px",
                        borderRadius: "3px",
                        objectFit: "contain",
                        backgroundColor: "whitesmoke",
                      }}
                      alt="cover"
                    />
                  )}
                </div>
                <div className="p-3">
                  {fileToCrop ? (
                    <div className="col-12 text-center">
                      <button
                        onClick={() => showCroppedImage()}
                        type="button"
                        className="btn btn-primary"
                        disabled={refresh}
                      >
                        {refresh && (
                          <div
                            style={{ width: "20px", height: "20px" }}
                            className="spinner-border text-light mr-1"
                          />
                        )}
                        Crop Photo
                      </button>
                    </div>
                  ) : (
                    <div className="custom-file mt-3">
                      <input
                        onChange={(event) =>
                          handleChangeCover(event.target.files)
                        }
                        type="file"
                        className="custom-file-input"
                        id="file-choose"
                        accept="image/*"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="file-choose"
                        style={{ borderRadius: "3px" }}
                      >
                        {refresh ? (
                          <div
                            style={{ width: "20px", height: "20px" }}
                            className="spinner-border text-dark mr-1"
                          />
                        ) : (
                          "Pilih gambar"
                        )}
                      </label>
                    </div>
                  )}
                  {isValidCover && (
                    <div
                      className={
                        fileToCrop
                          ? "col-12 text-center mt-1"
                          : "col-12 text-left p-0"
                      }
                    >
                      <MessageError msg={isValidCover} />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-8">
                <div className="form-group row">
                  <label htmlFor="judul" className="col-sm-2 col-form-label">
                    Judul
                  </label>
                  <div className="col-sm-10">
                    <input
                      {...register("judul", { required: "Judul harus diisi" })}
                      type="text"
                      className="form-control"
                      id="judul"
                      placeholder="Masukkan nama kelas"
                    />
                    {errors.judul && (
                      <MessageError msg={errors.judul.message} />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="desc" className="col-sm-2 col-form-label">
                    Deskripsi <br/>(Topik/ Ikhtisar/ Skema Pembelajaran)
                  </label>
                  <div className="col-sm-10">
                    <CKEditor
                      editor={ClassicEditor}
                      data={saveDesc}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setSaveDesc(data);
                        // console.log( { event, editor, data } );
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                    {isValidDesc && (
                      <MessageError msg={"Deskripsi harus diisi"} />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label mt-2">
                    Kategori
                  </label>
                  <div id="multipeSelect" className="col-sm-10 mt-2">
                    <Select
                      onChange={(value) => {
                        //   console.log("change");
                        setDefaultCatergory(value);
                        let chooseCategory = [];
                        value.forEach((item) =>
                          chooseCategory.push(item.value)
                        );
                        setSaveCategory(chooseCategory);
                      }}
                      closeMenuOnSelect={false}
                      isMulti
                      options={allDataCategory}
                      value={defaultCategory}
                      placeholder="- Pilih kategori kelas -"
                      isDisabled={true}
                    />
                    {validateCategory && (
                      <MessageError msg={validateCategory} />
                    )}
                  </div>
                </div>
                {isInstansi && (
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label mt-2">
                      Instruktur
                    </label>
                    <div id="multipeSelect" className="col-sm-10 mt-2">
                      <Select
                        onChange={(value) => {
                          //   console.log("change");
                          setDefaultInstruktur(value);
                          let chooseInstruktur = [];
                          value.forEach((item) =>
                            chooseInstruktur.push(item.value)
                          );
                          setSaveInstruktur(chooseInstruktur);
                        }}
                        closeMenuOnSelect={false}
                        isMulti
                        options={listInstruktur}
                        value={defaultInstruktur}
                        placeholder="- Pilih instruktur kelas -"
                      />
                    </div>
                  </div>
                )}
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">Level</label>
                  <div className="col-sm-10">
                    <select
                      {...register("level", { required: "Level harus diisi" })}
                      className="form-control"
                    >
                      <option value="">- Pilih level -</option>
                      <option value="1">Pemula</option>
                      <option value="2">Menengah</option>
                      <option value="3">Mahir</option>
                    </select>
                    {errors.level && (
                      <MessageError msg={errors.level.message} />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="harga" className="col-sm-2 col-form-label">
                    Harga
                  </label>
                  <div className="col-sm-10">
                    <input
                      onInput={(e) =>
                        (e.target.value =
                          !!e.target.value && Math.abs(e.target.value) >= 0
                            ? Math.abs(e.target.value)
                            : null)
                      }
                      {...register("harga", {
                        required: "Harga harus diisi",
                      })}
                      type="text"
                      className="form-control"
                      id="harga"
                      placeholder="Masukkan harga kelas"
                    />
                    {errors.harga && (
                      <MessageError msg={errors.harga.message} />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="persyaratan"
                    className="col-sm-2 col-form-label"
                  >
                    Persyaratan
                  </label>
                  <div className="col-sm-10">
                    <CKEditor
                      editor={ClassicEditor}
                      data={saveSyarat}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setSaveSyarat(data);
                        // console.log( { event, editor, data } );
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row mt-5">
                  <label className="col-sm-2 col-form-label" />
                  <div className="col-sm-10">
                    <button
                      type="button"
                      onClick={() => {
                        history.goBack();
                      }}
                      className="btn btn-outline-secondary"
                    >
                      <i className="fas fa-arrow-left mr-1" />
                      Kembali
                    </button>
                    <button type="submit" className="btn btn-primary ml-2">
                      <i className="fas fa-save mr-1" />
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
