import React, { useState, useEffect, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ContextProvider } from "../../context/BaseContext";
import DataTable from "../../component/DataTable";
import swal from "sweetalert";

export default function App() {
  const context = useContext(ContextProvider);
  const history = useHistory();
  let { path, url } = useRouteMatch();
  const [data, setData] = useState([]);

  async function getData() {
    let response = await context.getRequest(`instruktur`);
    if (response) {
        console.log(response.data);
      setData(response.data.data);
    }
  }

  async function deleteInstruktur(id) {
    swal({
      title: "Anda yakin?",
      text: "apakah anda akan menghapus instruktur ini?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Tidak jadi", "Ya, saya akan hapus!"],
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await context.getRequest(`delete-instruktur?id=${id}`);
        if (response) {
          swal("Hapus instruktur berhasil dilakukan", {
            icon: "success",
          });
          getData();
        }
      } else {
        swal("Tidak jadi hapus instruktur ini");
      }
    });
  }

  const RenderItem = ({ val }) => {
    return (
      <tr>
        <td>
          <div className="d-inline-block align-middle">
            <img
              src={val.avatar}
              alt="userImage"
              className="img-radius align-top m-r-10"
              style={{ width: "40px", height: "40px", objectFit: "cover" }}
            />
            <div className="d-inline-block">
              <h6 className="m-b-0">{val.nama}</h6>
              <p className="m-b-0">{val.title ? val.title : "-"}</p>
            </div>
          </div>
        </td>
        <td>{val.email}</td>
        <td>{val.phone}</td>
        <td id="social-media">
          <div>
            {val.website && (
              <p className="mb-1 mr-1 badge bg-secondary">
                <a className="text-white" href={val.website}>
                  <i className="fas fa-globe mr-1" />
                  link website
                </a>
              </p>
            )}
            {val.linkedin && (
              <p style={{ backgroundColor: "#0e76a8" }} className="mb-1 badge">
                <a className="text-white" href={val.linkedin}>
                  <i className="fab fa-linkedin-in mr-1" />
                  linkedin profile
                </a>
              </p>
            )}
          </div>
          <div>
            {val.facebook && (
              <p
                style={{ backgroundColor: "#3b5998" }}
                className="mb-1 mr-1 badge"
              >
                <a className="text-white" href={val.facebook}>
                  <i className="fab fa-facebook-f mr-1" />
                  facebook profile
                </a>
              </p>
            )}
            {val.youtube && (
              <p style={{ backgroundColor: "#c4302b" }} className="mb-1 badge">
                <a className="text-white" href={val.youtube}>
                  <i className="fab fa-youtube mr-1" />
                  youtube
                </a>
              </p>
            )}
          </div>
        </td>
        <td>
          <i className="fas fa-ellipsis-h" />
          <div className="overlay-edit">
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                history.push(`${path}/edit/${val.id}`);
              }}
              className="btn btn-icon btn-warning"
            >
              <i className="feather icon-edit-2" />
            </a>
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                deleteInstruktur(val.id);
              }}
              type="button"
              className="btn btn-icon btn-danger"
            >
              <i className="feather icon-trash-2" />
            </a>
          </div>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Instruktur</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a onClick={(e) => e.preventDefault()} href="#!">
                    Instruktur
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card user-profile-list">
        <div className="card-header">
          <button
            onClick={() => {
              history.push(`${path}/add`);
            }}
            className="btn btn-primary"
          >
            <i className="fa fa-plus mr-1" />
            Tambah Instruktur
          </button>
        </div>
        <div className="card-body">
          <div className="dt-responsive table-responsive">
            <DataTable
              className={"table table-striped table-bordered nowrap"}
              data={data}
              totalRows={data.length}
              show={[10, 20, 30, 40, 50]}
              onChangeSearch={(val) => {
                console.log(val);
              }}
              onChangeLimit={(val) => {
                console.log(val);
              }}
              onChangePage={(val) => {
                console.log(val);
              }}
              column={["Nama", "Email", "No Ponsel", "Social Media", "Action"]}
              renderItem={(item, i) => <RenderItem val={item} key={i} />}
            />
          </div>
        </div>
      </div>
    </>
  );
}
