import React, { useState, useEffect, useContext } from "react";
import Template from "../../component/Template";
import { ContextProvider } from "../../context/BaseContext";
import moment from "moment";
import "moment/locale/id";

export default function ScheduleLiveKelas() {
  const context = useContext(ContextProvider);
  const [toDay, setToDay] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  useEffect(() => {
    async function getData() {
      let response = await context.getRequest("live-today");
      if (response) {
        var data = response.data.data;
        setToDay(data.today);
        setUpcoming(data.upcoming);
        console.log(response);
      }
    }
    getData();
  }, []);
  return (
    <Template>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Jadwal Live Kelas</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!">Jadwal Live Kelas</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title p-2">Jadwal hari ini</h5>
          {toDay?.map((val, i) => {
            var dd = moment().format("YYYY-MM-DD");
            var e = moment(
              dd + " " + val.endtime,
              "YYYY-MM-DD hh:mm:ss"
            ).unix();
            var s = moment(
              dd + " " + val.start_button,
              "YYYY-MM-DD hh:mm:ss"
            ).unix();
            var n = moment().unix();

            var zoom = JSON.parse(val.data);
            // console.log('MOMENT ',moment.unix(n).format('hh:mm:ss'),moment.unix(s).format('hh:mm:ss'),moment.unix(e).format('hh:mm:ss'),dd+" "+val.end_time);
            if (n >= s && n <= e) {
              // alert('berlangsung')
              return (
                <div key={i} className="d-flex justify-content-between">
                  <div className="p-2">
                    <span className="badge badge-success mr-1">
                      Sedang berlangsung
                    </span>
                    <span className="mr-1">
                      {`${val.class_title} - ${val.topic} : `}{" "}
                      {moment(val.start_time, "YYYY-MM-DD hh:mm:ss").calendar()}{" "}
                      - {moment(val.endtime, "hh:mm:ss").format("LT")}
                    </span>
                  </div>
                  <div className="p-1">
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        refreshSignature(val.zoom_id);
                      }}
                      href={`#!`}
                    >
                      <button className="btn btn-sm btn-primary mr-2">
                        Join
                      </button>
                    </a>
                    <a target="_blank" href={zoom.start_url}>
                      <button className="btn btn-sm btn-info mr-2">
                        Join via zoom
                      </button>
                    </a>
                  </div>
                </div>
              );
            } else if (n <= s) {
              return (
                <div key={i} className="d-flex justify-content-between">
                  <div className="p-2">
                    <span className="badge badge-secondary mr-1">
                      Belum dimulai
                    </span>
                    <span className="mr-1">
                      {`${val.class_title} - ${val.topic} : `}{" "}
                      {moment(val.start_time, "YYYY-MM-DD hh:mm:ss").calendar()}{" "}
                      - {moment(val.endtime, "hh:mm:ss").format("LT")}
                    </span>
                  </div>
                  <div className="p-1"></div>
                </div>
              );
            } else if (n >= e) {
              return (
                <div key={i} className="d-flex justify-content-between">
                  <div className="p-2">
                    <span className="badge badge-danger mr-1">Berakhir</span>
                    <span className="mr-1">
                      {`${val.class_title} - ${val.topic} : `}{" "}
                      {moment(val.start_time, "YYYY-MM-DD hh:mm:ss").calendar()}{" "}
                      - {moment(val.endtime, "hh:mm:ss").format("LT")}
                    </span>
                  </div>
                  <div className="p-1"></div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </Template>
  );
  async function refreshSignature(meetingID) {
    let response = await context.getRequest(
      "signature-mentor?zoom_id=" + meetingID
    );
    if (response) {
      console.log(response.data);
      if (response.data.status) {
        window.open(
          process.env.PUBLIC_URL + `/zoom-fullscreen/${response.data.data}`,
          "_blank"
        );
      } else {
        alert(response.data.message);
      }
    }
  }
}
