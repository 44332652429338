import React,{useContext,useState,useEffect} from 'react'
import {useRouteMatch,useHistory} from 'react-router-dom'
import { useForm } from "react-hook-form";
import {  useParams } from "react-router";
import MessageError from '../../component/MessageError'
import {ContextProvider} from '../../context/BaseContext'
import {setStorage,encryptString,decodeJWT} from '../../util/Functions'
export default function App(){
    const history=useHistory()
    const {token}=useParams();
    const [username,setUsername]=useState('')
    const [code,setCode]=useState('')
    const context=useContext(ContextProvider)
    const [refresh,setRefresh]=useState(false)
    const { register, handleSubmit, watch, formState: { errors },reset } = useForm();
    async function getProfile(){
        let response=await context.getRequest('profile_mentors2',false);
        let str=JSON.stringify(response.data.data);
        // str=encryptString(str)
        await setStorage('profile_mentor',str);
        console.log(JSON.parse(str))
    }
    useEffect(() => {
        localStorage.setItem('token',token)
        
    },[])
    let { url } = useRouteMatch();
    return(
        <>
            <table>
                <tr>
                    <td>Username</td>
                    <td>
                        <input type="text" name="username" onChange={(e)=>setUsername(e.target.value)}/>
                    </td>
                </tr>
                <tr>
                    <td>token (contact bakti)</td>
                    <td>
                        <input type="password" name="provider" onChange={(e)=>setCode(e.target.value)}/>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <input type="submit" onClick={async()=>{
                            setRefresh(true)
                            // console.log("DATA ",data)
                            let form = JSON.stringify({
                                "email": username,
                                "token":code,
                            })
                            let response=await context.postRequest('login_mentor_check',form)
                            if(response){
                                console.log("RESPONSE : ",response.access_token)
                                
                                let auth=await encryptString(username)
                                await setStorage('auth', auth)
                                await setStorage('token', response.access_token)
                                await setStorage('role','mentor')
                                await getProfile()
                                context.setValue('login',true)
                                history.replace('/')
                                reset()
                            }else{
                                alert('gagal');
                            }
                            setRefresh(false)
                        }} name="provider" value="masuk"/>
                    </td>
                </tr>
            </table>
        </>
    )
}