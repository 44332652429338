import React, { useContext, useRef, useState, useEffect } from "react";
import Template from "../../component/Template";
import { useForm } from "react-hook-form";
import { ContextProvider } from "../../context/BaseContext";
import MessageError from "../../component/MessageError";
import {
  decryptString,
  encryptString,
  setStorage,
  getStorage,
} from "../../util/Functions";
import { useHistory } from "react-router-dom";

export default function App() {
  const history = useHistory();
  const inputFile = useRef(null);
  const context = useContext(ContextProvider);
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [imageError, setImageError] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    // setRefresh(true)
    //console.log("DATA ",data)
    let form = new FormData();
    form.append("no_rekening", data.no_rekening);
    form.append("rekening_atas_nama", data.atas_nama);
    form.append("nama_bank", data.nama_bank);

    let response = await context.postRequest("updateMentor", form);
    if (response) {
      // console.log(response)
      if (response.status == true) {
        context.setNotification("success", response.message);
        await setStorage("profile_mentor", JSON.stringify(response.data));
      } else {
        context.setNotification("danger", response.message);
      }
    }
  };
  async function getProfile() {
    let mentor = await getStorage("profile_mentor");
    if (mentor) {
      var json = JSON.parse(mentor);
      console.log(json);
      setValue("no_rekening", json.no_rekening);
      setValue("atas_nama", json.rekening_atas_nama);
      setValue("nama_bank", json.nama_bank);
    }
  }
  function checkIfNull(d) {
    if (d == "null" || d == null) {
      return "";
    }
    return d;
  }
  useEffect(() => {
    getProfile();
  }, []);
  return (
    <Template>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">No Rekening</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!">Ubah no rekening</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h5>Ubah No Rekening</h5>
          {/* <button onClick={()=>{history.push(`${path}/add`)}} className="btn btn-primary">Tambah Kuis</button> */}
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-1" style={{ width: "100%" }}></div>
            <div className="col-md-8">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    No Rekening
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      {...register("no_rekening", {
                        required: "No rekening harus diisi",
                      })}
                      className="form-control"
                      id="Password"
                      placeholder="Email"
                    />
                    {errors.no_rekening ? (
                      <MessageError msg={errors.no_rekening.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Atas Nama
                  </label>
                  <div className="col-sm-6">
                    <input
                      autocomplete="off"
                      type="text"
                      {...register("atas_nama", {
                        required: "Atas nama harus diisi",
                      })}
                      className="form-control"
                      id="Password"
                      placeholder="Nama Lengkap"
                    />
                    {errors.atas_nama ? (
                      <MessageError msg={errors.atas_nama.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  >
                    Bank
                  </label>
                  <div className="col-sm-6">
                    <input
                      autocomplete="off"
                      type="text"
                      {...register("nama_bank", {
                        required: "Nama bank harus diisi",
                      })}
                      className="form-control"
                      id="Password"
                      placeholder="Title"
                    />
                    {errors.nama_bank ? (
                      <MessageError msg={errors.nama_bank.message} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-2 col-form-label"
                  ></label>
                  <div className="col-sm-6">
                    <button
                      type="button"
                      onClick={() => {
                        history.goBack();
                      }}
                      className="btn btn-outline-secondary mr-2"
                    >
                      <i className="fas fa-arrow-left mr-1" />
                      Kembali
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ width: 150 }}
                    >
                      Ubah
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
