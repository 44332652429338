import React, { useEffect, useState, useRef, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/id'
import { getStorage } from '../../../util/Functions';
import { ContextProvider } from '../../../context/BaseContext';

const MessageItem = ({
  message,
  setId
}) => {
  const [isHover, setIsHover] = useState(false);
  const defaultImage = 'assets/images/empty_user.png';
  const [unread, setUnread] = useState(message.unread);
  const [errorAvatar, setErrorAvatar] = useState(false);
  const messageBtn = useRef();

  /*LAST CHAT STATE*/
  const [newestChatText, setNewestChatText] = useState("");
  const [newestChatTime, setNewestChatTime] = useState("");
  const [newestChatSender, setNewestChatSender] = useState("");

  function getDate() {
    const date = moment(message.created_at).format()
    const diff = moment().diff(date, 'days')

    if (diff == 0) {
      setNewestChatTime(moment(date).format('LT'))
    } else if (diff == 1) {
      setNewestChatTime('Kemarin')
    } else {
      setNewestChatTime(moment(date).format('L'))
    }
  }

  async function senderName() {
    let profile_mentor = await getStorage('profile_mentor')
    let user = JSON.parse(profile_mentor)
    let disname = message.users.name

    if (user.id == message.user_id) {
      disname = 'Anda'
    }
    setNewestChatSender(disname)
  }

  async function senderText() {
    let text = ''
    if (message.content_type == 'text') {
      text = message.content
    } else {
      text = ' Gambar'
    }
    setNewestChatText(text)
  }

  useEffect(() => {
    getDate()
    senderName()
    senderText()
  })
  return (
    <li
      className="border-bottom"
      style={{
        width: "100%",
        backgroundColor: unread!=0?'#D71C2030':'white',
      }}
    >
      <button
        ref={messageBtn}
        className="px-0 btn d-flex text-left"
        style={{
          width: "100%",
          height: "70px",
          backgroundColor: isHover ? "rgba(221,221,221,0.2)" : "transparent",
          overflow: "hidden"
        }}
        title={"Chat"}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => {
          setId(message.classes);
          setUnread(0)
          // setChatToDisplay(chat);
        }}
      >
        <div className="col-auto p-r-0">
          <div className="u-img">
            {unread!=0?
            <div style={{height:40}} className='d-flex justify-content-center align-items-center wid-40 img-radius bg-primary text-light'>
              <p style={{marginBlock:'auto'}} className='font-weight-bold'>{unread>99?'99+':unread}</p>
            </div>
            :
            <img
              style={{ width: "40px", height: "40px", objectFit: "cover" }}
              src={
                errorAvatar
                  ? defaultImage
                  : message.classes.img
              }
              alt="user-avatar"
              className="img-radius"
              onError={() => setErrorAvatar(true)}
            />}
            {/* <span className="tot-msg">2</span> */}
          </div>
        </div>
        <div className="col">
          <h6 className={`m-b-5 d-flex justify-content-between ${unread!=0?'font-weight-bold':'font-weight-normal'}`}>
            {message.classes.title}
            <span className={`text-muted float-right f-13 ${unread!=0?'font-weight-bold':'font-weight-normal'}`}>
              {newestChatTime}
            </span>
          </h6>
          <p
            className={`text-muted m-b-0 ${unread!=0?'font-weight-bold':'font-weight-normal'}`}
            style={{
              height: "20px",
              overflow: "hidden"
            }}
          >
            {newestChatSender + ' : '}
            {message.content_type == 'text' ? null : <i class="fas fa-camera"></i>}
            {newestChatText}
          </p>
        </div>
      </button>
    </li>
  );
}

export default MessageItem;